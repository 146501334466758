/*********************
Ecommerce
**********************/


/* Product card */

.card-product {
    margin: $element-top-margin 0 $element-bottom-margin 0;
    ul {
        height: 0;
        margin: 0;
        padding: 0;
        li {
            margin-top: 40px;
        }
        li:first-of-type {
            margin-top: 0;
        }
    }
    .top-btn {
        margin-bottom: -70px;
        text-align: right;
    }
    .card-image {
        padding: 0;
        margin: 0;
        h5 {
            margin: 0;
        }
        .card-label {
            margin-bottom: -27px;
            z-index: 12;
            position: relative;
            .label {
                border-radius: 0;
                font-weight: 300;
            }
        }
    }
    .card-content {
        padding: 0;
    }
    .product-title {
        margin: 0;
        padding: 5px;
        &:hover {
            transition-duration: 0.7s;
        }
    }
    .price {
        margin: 0;
        padding: 5px;
        p {
            text-align: center;
        }
        .discount {
            text-decoration: line-through;
            margin-left: 10px;
        }
    }
    .rating {
        margin-top: -20px;
        z-index: 10;
        &:hover {
            .material-icons {
                color: gold;
                transition: all 0.4s linear;
            }
        }
        .material-icons {
            font-size: 1em;
        }
    }
    .btn-floating {
        margin-top: -20px;
        z-index: 10;
        .material-icons {
            font-size: 1em;
        }
    }
    .card-btn {
        margin-top: 0;
    }
    .card-reveal {
        z-index: 11;
    }
}


/* Quick Look Modal */

.modal-qlook {
    width: auto;
    margin: 5em;
    .price {
        margin: 0;
        padding: 5px;
        .discount {
            text-decoration: line-through;
            margin-left: 10px;
        }
    }
    .label {
        border-radius: 0;
        font-weight: 300;
        font-size: 0.7em;
    }
    .quantity {
        width: 40px;
    }
}


/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {
    .modal-qlook {
        width: auto;
        margin: 1em;
    }
}

.product-panel {
    margin: $element-top-margin 0 $element-bottom-margin 0;
    .price {
        margin: 0;
        padding: 5px;
        .discount {
            text-decoration: line-through;
            margin-left: 10px;
        }
    }
    .label {
        border-radius: 0;
        font-weight: 300;
        font-size: 0.7em;
    }
    .quantity {
        width: 40px;
    }
}


/* Guick Look Gallery */

.quick-look-gall {
    display: flex;
    ul.thumbnails-carousel {
        li {
            height: 100px;
            width: 50px;
            overflow: hidden;
            float: none;
            margin-top: 10px;
            img {
                height: 100px;
            }
        }
        li:first-of-type {
            margin-top: -3px;
        }
    }
}


/* Collection Card */

.collection-card {
    width: auto;
    position: relative;
    margin: $element-top-margin 0 $element-bottom-margin 0;
    .btn-floating {
        margin-bottom: -100px;
        position: relative;
    }
    .card-footer {
        margin-top: -140px;
        position: absolute;
        width: 100%;
        color: #fff;
        height: auto;
        padding: 14px;
        text-align: center;
    }
    .material-icons {
        position: absolute;
        margin-left: 5px;
    }
}

.sort-panel {
    margin: $element-top-margin 0 $element-bottom-margin 0;
    .col-title {
        margin-bottom: 20px;
    }
    .categories {
        label {
            color: #000;
            font-weight: 400;
            span {
                color: #666;
            }
        }
    }
    .color-sorting {
        a {
            padding: 0;
            margin: 5px;
            height: 25px;
            width: 25px;
        }
    }
    .rectangle {
        padding: 7px;
        ;
        a {
            width: 100%;
            margin: 0;
            margin-top: 7px;
        }
    }
    .color-sorting .dropdown-content {
        width: 100%;
    }
    .number-sorting a {
        color: #666;
        margin: 0;
        margin-top: 8px;
        &:first-of-type {
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 992px) {
    .sort-panel {
        .add-op {
            margin-top: 30px;
        }
    }
}


/* Minimalist card */

.card-min {
    .activator {
        margin-top: -25px;
        margin-right: 20px;
        float: right;
    }
    h5 {
        margin-top: 11px;
        ;
    }
    .card-label {
        z-index: 12;
        position: absolute;
        .label {
            border-radius: 2px;
            font-weight: 300;
            font-size: 1em;
            padding: 10px 20px 10px 20px;
        }
    }
    .card-content {
        h5 {
            margin-top: 8px;
            .material-icons {
                position: absolute;
                margin-left: 6px;
            }
        }
        a {
            color: #000;
        }
    }
}

@media only screen and (max-width: 768px) {
    .card-min {
        .card-label {
            margin-top: -5px;
            .label {
                font-size: 0.5em;
                padding: 5px 10px 5px 10px;
            }
        }
    }
}

.cart-counter {
    position: absolute;
    margin-left: -12px;
    margin-top: -5px;
    z-index: 10;
    background-color: rgba(244, 67, 54, 0.9);
}

@media only screen and (max-width: 768px) {
    .cart-counter {
        font-size: 8px;
        background-color: rgba(244, 67, 54, 0.9);
    }
}