.navbar {
  margin-bottom: 0;
  height: auto;

  .navbar-brand {
    height: auto;
    font-size: initial;
    line-height: unset;
    pointer-events: all;

    @include breakpoint(md) {
      padding: 40px 40px 0 40px;
    }

    svg {
      height: 30px;
      width: auto;

      @include breakpoint(md) {
        height: 80px;
      }
    }

    &:hover {
      svg g .nav-logo {
        transition: fill 0.3s linear;
        fill: $int-nav-yellow;
      }
    }
  }
}

.navbar-header {
  pointer-events: all;
  button.navbar-btn {
    box-shadow: none;
    padding: 15px 13px;
    margin: 0;
    position: absolute;
    right: 0;

    @include breakpoint(md) {
      margin: 0;
      padding: 69px 40px 0;
    }

    svg {
      height: 16px;

      g {
        rect.nav-icon {
          transition: fill .3s linear, x .5s linear;
          width: 100%;
        }
      }

      @include breakpoint(md) {
        height: 28px;
      }
    }

    &:hover {
      box-shadow: none;

      svg g {
        rect.nav-icon {
          x: 0;
          fill: $int-nav-yellow;
        }
      }
    }
  }
}

.navbar-fixed-top {
  pointer-events: none;

  @include breakpoint(md) {
    padding: 0;
  }
}

.navbar-menu {
  img {
    width: 30px;
    height: 16px;
  }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  z-index: 1000;
}

.contact-element {
  position: relative;
  visibility: hidden;
  overflow: hidden;
  background: rgb(2, 48, 150);
  opacity: 0;
  height: 611px;
  transition: opacity .5s ease-in-out, visibility .5s ease-in-out, transform .5s ease-in-out;
  z-index: 1010;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: transform .5s ease-in-out;

    @media (min-width: 0) {
      transform: translateY(-520px);
    }
    @media (min-width: 544px) {
      transform: translateY(-522px);
    }
    @media (min-width: 768px) {
      transform: translateY(-576px);
    }
    @media (min-width: 992px) {
      transform: translateY(-570px);
    }
  }

  &.slide-away {
    opacity: 0;
    transition: transform .5s ease-in-out, opacity .5s ease-in-out;

    @media (min-width: 0) {
      transform: translateY(520px)
    }
    @media (min-width: 544px) {
      transform: translateY(522px);
    }
    @media (min-width: 768px) {
      transform: translateY(576px);
    }
    @media (min-width: 992px) {
      transform: translateY(570px);
    }
  }

  .close-contact {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 80px;
    height: 80px;
    background: #000 url('/images/close.svg');
    background-size: 34px;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1012;

    @media (max-width: 543px) {
      width: 40px;
      height: 40px;
      background-size: 17px;
    }
  }

  .footer {
    top: 0;
  }
  
}

#nav-footer-modal-overlay {
  z-index: 1012;
}
.footer-modal.modal-over-modal {
  z-index: 1013;
}

#nav-modal {
  position: fixed;
  height: 100vh;
  background: $int-bright-blue-2;
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  top: 0;
  transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
  width: 100%;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .nav-close {
    width: 40px;
    height: 40px;
    background: #000 url('/images/close.svg');
    background-position: center;
    background-size: 17px;
    background-repeat: no-repeat;
    right: 0;
    position: absolute;
    z-index: 1011;

    @include breakpoint(sm) {
      width: 80px;
      height: 80px;
      background-size: 34px;
    }
  }

  &::after {
    content: '';
    background-image: url("/images/box-pattern.svg");
    background-size: 234px 183px;
    -moz-box-shadow: inset 0 7px 21px -7px rgba($int-black, 0.3);
    -webkit-box-shadow: inset 0 7px 21px -7px rgba($int-black, 0.3);
    box-shadow: inset 0 7px 21px -7px rgba($int-black, 0.3);
    opacity: 0.05;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
  }

  .center-nav {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .card-stack {
    width: 300px;
    padding: 0;
    list-style: none;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;

    // 60px * number of cards that hang down
    height: calc(275px + 240px);

    @include breakpoint(sm) {
      width: 500px;
      height: calc(460px + 240px);
    }
    @include breakpoint(md) {
      //margin-top: 60px;
    }

    li.card {
      border-radius: 5px;
      height: 275px;
      -webkit-box-shadow: 0px 5px 30px 5px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 5px 30px 5px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 5px 30px 5px rgba(0, 0, 0, 0.75);
      position: absolute;
      width: 100%;

      @include breakpoint(sm) {
        height: 460px;
      }

      &:first-of-type {
        text-align: center;
        .card-content {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
        h1 {
          font-size: 18px;
          line-height: 24px;
          color: $int-teal;
          margin-top: 0;
          margin-bottom: 16px;
          transition: color 0.3s linear;

          @include breakpoint(sm) {
            font-size: 30px;
            line-height: 46px;
            margin-bottom: 26px;
          }

        }

        a {
          display: block;
          font-size: 12px;
          line-height: 18px;
          color: $int-bright-blue-2;
          font-family: $montserrat-bold;

          @include breakpoint(sm) {
            font-size: 18px;
          }

          &:first-of-type {
            margin-bottom: 18px;

            &:hover {
              h1 {
                color: $int-white;
              }
            }

            @include breakpoint(sm) {
              margin-bottom: 27px;
            }
          }

          &:nth-of-type(2), &:nth-of-type(3) {
            margin-bottom: 20px;

            @include breakpoint(sm) {
              margin-bottom: 32px;
            }
          }

          &.nav-btn {
            text-transform: uppercase;
            color: $int-bright-blue-3;
            background-color: $int-bright-blue-2;
            font-size: 18px;
            line-height: 50px;
            font-family: $montserrat-bold;
            width: 150px;
            border-radius: 3px;
            transition: color 0.3s linear;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 22px;

            &:hover {
              color: $int-white;
            }

            @include breakpoint(sm) {
              width: 200px;
              height: 60px;
              line-height: 60px;
              margin-top: 45px;
            }
          }
        }
      }

      &:nth-of-type(n+1) {
        background: #0138FF;
        z-index: 1010;
        top: -60px
      }
      &:nth-of-type(n+2) {
        background: #023096;
        z-index: 1009;
        top: 0;
      }
      &:nth-of-type(n+3) {
        background: #0431AF;
        z-index: 1008;
        top: 60px;
      }
      &:nth-of-type(n+4) {
        background: #00478E;
        z-index: 1007;
        top: 120px;
      }
      &:nth-of-type(n+5) {
        background: #044fb8;
        z-index: 1006;
        top: 180px;
      }
      &:nth-of-type(n+6) {
        background: #0763E8;
        z-index: 1005;
        top: 240px;
      }

      &:nth-of-type(n+2) {
        transition: top .15s linear;
        &:hover {
          &:nth-of-type(n+2) {
            top: 10px;
          }
          &:nth-of-type(n+3) {
            top: 70px;
          }
          &:nth-of-type(n+4) {
            top: 130px;
          }
          &:nth-of-type(n+5) {
            top: 190px;
          }
          &:nth-of-type(n+6) {
            top: 250px;
          }
        }
        &.rise {
          &:nth-of-type(n+2) {
            top: -10px;
          }
          &:nth-of-type(n+3) {
            top: 45px;
          }
          &:nth-of-type(n+4) {
            top: 100px;
          }
          &:nth-of-type(n+5) {
            top: 155px;
          }
          &:nth-of-type(n+6) {
            top: 205px;
          }

          a {
            padding-bottom: 20px;
          }
        }
        a {
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: center;
          line-height: 15px;
          padding: 20px;
          font-family: $montserrat-bold;
          color: #53C3DE;
        }
      }

      a {
        transition: color .25s linear, padding-bottom .15s linear;
        text-transform: uppercase;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }

        &:hover {
          color: $int-white;
        }
      }
    }
  }

  .navigation-menu-actions {
    position: relative;
    top: 550px;
    width: 300px;
    margin: 0 auto;
    display: none;

    @include breakpoint(sm) {
      width: 500px;
    }

    .navigation-menu-actions-item {
      display: inline-block;
      height: auto;
      padding: 5.5px;

      @include breakpoint(sm) {
        margin: 0 15px;
        position: relative;
        top: 191px;
      }

      img {
        transition: transform 0.25s ease-in-out;
        position: relative;
        z-index: 2;
        width: 60px;

        @include breakpoint(sm) {
          width: 80px;
        }
      }

      p {
        font-family: $montserrat-bold;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: $int-white;
        text-transform: uppercase;
        margin-top: -12px;
        opacity: 0;
        transition: opacity 0.25s linear;

        @include breakpoint(md) {
          margin-top: -20px;
        }

        @include breakpoint(lg) {
          margin-top: -17px;
          font-size: 18px;
        }
      }

      &:hover {
        text-decoration: none;
        color: $int-white;
        cursor: pointer;
        img {
          transform: translateY(-25px);
          transition: transform 0.15s linear;

          @include breakpoint(md) {
            transform: translateY(-34px);
          }
          @include breakpoint(md) {
            transform: translateY(-32px);
          }
        }
        p {
          opacity: 1;
          transition: opacity .25s linear;
          color: $int-white;
          text-decoration: none;
        }
      }
    }
  }
}
