.carriers-page {
  .page-wrapper {
    overflow-x: hidden;
  }
}
.carriers {
  @include gradient( $int-light-blue, $int-dark-blue );
  margin-left: -15px;
  margin-right: -15px;
  position: relative;

  .row {
    padding-left: 30px;
    padding-right: 30px;

    div {
      height: 100vh;
      text-align: center;
      @include breakpoint(sm) {
        //height: 90vh;
      }
      @include breakpoint(md) {
        text-align: left;
        //height: 97vh;
      }
    }
  }

  &--wrapper {
    position: relative;
    width: 100%;
  }

  h1 {
    color: $int-yellow;
    font-size: 24px;
    line-height: 29px;

    @include breakpoint(sm) {
      font-size:36px;
      line-height: 48px;
      margin-top: 150px;
    }
    @include breakpoint(md) {
      width: 360px;
    }
    @include breakpoint(xlg) {
      font-size: 48px;
      line-height: 48px;
      width: 470px;
    }
  }

  p {
    color: $int-white;
    font-size: 14px;
    line-height: 17px;
    @include breakpoint(sm) {
      font-size: 18px;
      line-height: 24px;
    }
    @include breakpoint(md) {
      width: 360px;
    }
    @include breakpoint(xlg) {
      font-size: (21/16) + em;
    }
  }

  a {
    text-align: center;
    font-size: (18/16) + em;
  }

  h1 {
    margin-top: 15vh;

    @include breakpoint(lg) {
      margin-top: 31.25vh;
    }
  }


  h1,
  p {
    position: relative;
    z-index: 1;
    max-width: 230px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(sm) {
      max-width: 340px;
    }

    @include breakpoint(md) {
      margin-left: 40px;
    }

    @include breakpoint(lg) {
      margin-left: 10vw;
      max-width: 470px;
    }
  }

  p.link-button,
  a.int-button {
    position: relative;
    z-index: 1;
    max-width: 230px;
    margin-left: auto;
    margin-right: auto;

    // @include breakpoint(sm) {
    //   max-width: 340px;
    // }

    // @include breakpoint(md) {
    //   margin-left: 40px;
    // }

    @include breakpoint(lg) {
      max-width: 470px;
    }
  }

  a.int-button,
  p {
    margin-top: 23px;
  }

  p.int-button,
  a.int-button {
    color: $int-bright-blue-3;
    background: $int-bright-blue-2;
    font-family: $montserrat-bold;
    font-size: 18px;
    transition: color .25s linear;
    cursor: pointer;

    &:hover,
    &:active {
      text-decoration: none;
      color: $int-white;
    }

    @include breakpoint(sm) {
      margin-top: 65px;
    }

    @include breakpoint(md) {
      margin-top: 30px;
    }
  }

  p.int-button {
    width: 200px;
    padding: 20px 10px;
  }
  
  p.int-button#access_submit {
    margin: 0 20px;
  }

  a.int-button {
    margin-bottom: 40px;
  }

  img.carrier--img__hero {
    position: absolute;
    bottom: 60px;
    left: 40%;
    height: 55%;
    transform: translateX(-50%);

    @include breakpoint(sm) {
      bottom: 100px;
    }
    @include breakpoint(md) {
      //height: 95%;
      //bottom: 20px;
      height: 70%;
      bottom: 70px;
    }
    @include breakpoint(lg) {
      //height: 110%;
      height: 90%;
      bottom: 0;
    }
  }

  img.carrier--img__people {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%);
    height: 28%;
    @include breakpoint(sm) {
      height: 30%;
    }
    @include breakpoint(md) {
      height: 40%;
    }
    @include breakpoint(lg) {
      left: 78%;
    }
  }


  
  .confirm-modal {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, .5);
      visibility: hidden;
      opacity: 0;
      z-index: 10;
      transition: opacity .25s ease;

      .modal-inner {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          border: solid blue 2px;
          border-radius: 10px;
          background-color: $int-bright-blue-3;
          height: 35%;
          width: 30%;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, .9);

          @include breakpoint(xs) {
            height: 60%;
            width: 95%;
          }
          @include breakpoint(sm) {
            width: 80%;
          }
          @include breakpoint(md) {
            width: 60%;
          }
          @include breakpoint(lg) {
            height: 50%;
            width: 40%;
          }
          @include breakpoint(xlg) {
            height: 40%;
          }

          h5 {
            margin-top: 40px;
            padding: 20px 30px;
            text-align: center;
          }
      }
      
    }
    .confirm-modal.visible {
        opacity: 1;
        visibility: visible;

    }
    .confirm-modal.visible.transparent {
        opacity: 0;
    }
    .modal-close {
      position: absolute;
      font-size: 1.5rem;
      font-weight: 600;
      cursor: pointer;
      color: $int-bright-blue-2;
      top: 5%;
      left: 3%;
    }
    .access-group {
      display: flex;
      flex-direction: row;
      padding: 20px;
      align-items: baseline;
      justify-content: space-around;
      max-width: 100%;

      @include breakpoint(xs) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      @include breakpoint(sm) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      @include breakpoint(md) {
        flex-direction: column;
        justify-content: space-around;
      }
      @include breakpoint(xlg) {
        flex-direction: row;
      }

      input {
        width: 300px;
        border-bottom: solid $int-bright-blue-2 1px;
        margin: 0 20px;

        @include breakpoint(xs) {
          width: 150px;
          margin-bottom: 20px;
        }
        @include breakpoint(md) {
          width: 300px;
        }
      }
      input::placeholder {
        color: $int-bright-blue-2;
      }
    }
    .access-error {
      text-align: center;
      color: red;
      visibility: hidden;
    }
    .access-error.visible {
      visibility: visible;
    }
    .button-wrapper {
      visibility: hidden;
    }
    .button-wrapper.visible {
      visibility: visible;
    }
}