.quote-modal-overlay {
  background-color: $int-bright-blue-2;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  z-index: 1002;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

#quote-modal {
  position: fixed;
  height: calc(100vh - 80px);
  overflow-y: auto;
  z-index: 1002;
  background: $int-medium-blue-2;
  color: white;
  font-size: 12px;
  border-radius: 5px;
  //display: block;
  //display: none;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  width: 320px;
  opacity: 0;
  transition: opacity .25s linear;
  //border: 10px solid $int-bright-blue-2;
  top: 0;
  pointer-events: none;
  margin-top: 40px;
  margin-bottom: 40px;

  @include breakpoint(md) {
    width: 649px;
  }

  @include breakpoint(lg) {
    width: 830px;
  }

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .overlay {
    background-color: $int-bright-blue-2;
    opacity: 0;
    position: absolute;
    z-index: 100;
    transition: opacity 0.3s linear;

    &.visible {
      opacity: .8;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity 0.3s linear;
    }
  }

  .quote-block {
    @include breakpoint(md) {
      width: 604px;
    }
    @include breakpoint(lg) {
      margin: 0 auto;
    }
  }

  .quote-form-wrapper {
    @include breakpoint(md) {
      margin-left: 22.5px;
      margin-right: 22.5px;
    }
  }

  .wrapper2 {
    margin-left: 32.5px;
    margin-right: 32.5px;

    &.reply-hr {
      margin-top: 30px;
      margin-bottom: 30px;

      hr.hr2 {
        margin-top: 0;
      }

      @include breakpoint(md) {
        margin-bottom: 0;
      }
    }

    @include breakpoint(md) {
      //margin-left: 22.5px;
      //margin-right: 22.5px;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .calendar-div {
    input {
      &:focus {
        box-shadow: none;
      }
    }
  }

  select {
    display: block;
  }

  hr {
    border-top: solid 1px $int-medium-blue;
    margin-top: 0;
    margin-bottom: 0;

    @include breakpoint(md) {
      width: 255px;
      float: left;
      width: 100%
    }

    &.hr2 {
      @include breakpoint(md) {
        position: absolute;
        //width: 255px;
        margin-top: 15px;
        width: 100%;
      }
    }
  }

  h4 {
    font-size: 24px;
    line-height: 24px;
    color: $int-bright-blue-3;
    font-family: $roboto-regular;
    margin-top: 0;
    margin-bottom: 15px;

    &.quote-title {
      margin-top: 30px;

      @include breakpoint(lg) {
        font-size: 36px;
        line-height: 36px;
        margin-top: 45px;
      }
    }

    &.value-title {
      margin-top: 30px;
      margin-bottom: 15px;
    }

    &.equipment-title, &.accessorials-title {
      margin-top: 30px;
      margin-bottom: 15px;
      padding-left: 32.5px;

      @include breakpoint(md) {
        padding-left: 22.5px;
        padding-left: 0;
      }
    }

  }

  h6 {
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 39px;
    margin-bottom: 15px;

    &.total-value {
      margin-top: 0;
      text-transform: none;
    }
  }

  .items-shipped {
    margin-top: 30px;
    //margin-bottom: 30px;
    padding-bottoM: 30px;


    h6 {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  .item-tagline {
    font-size: 14px;
    line-height: 21px;
    color: $int-bright-blue-3;
    margin-bottom: 30px;

    @include breakpoint(md) {
      margin-bottom: 15px;
    }
  }

  .description {
    text-transform: none;

    @include breakpoint(md) {
      //height: 100px;
      //margin-top: 0;
    }
  }

  .close-button {
    position: absolute;
    right: 15px;
    top: 15px;
    color: black;
    transition: all .3s linear;

    svg {
      transition: all .3s linear;

      circle {
        fill: $int-medium-blue;
        transition: fill .3s linear;
      }
      polygon {
        fill: $int-medium-blue-2;
        transition: fill .3s linear;
      }
    }

    &:hover {
      svg {
        circle {
          fill: $int-bright-blue-3;
        }
        polygon {
          fill: $int-medium-blue-2;
        }
      }
    }
  }

  .add-item-button {
    color: $int-white;
    position: relative;
    display: inline-flex;
    right: -1px;
    top: 0;
    margin: 50px 0;
    transition: all .3s linear;

    &:focus {
      outline: none;
    }

    &:hover {
      svg {
        line {
          stroke: $int-white;
        }
      }
    }

    span {
      padding-left: 15px;
      padding-top: 14px;
      text-transform: uppercase;
    }

    svg {
      height: 40px;
      width: auto;

      line {
        stroke: $int-medium-blue-2;
        transition: stroke .3s linear;
      }
    }
  }

  .picker__holder {
    max-width: 100vw;
  }

  .info {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 5px;

    &:focus {
      outline: none;
    }

    img {
      height: 16px;
      width: auto;
    }

    &.collapse-info {
      position: absolute;
      top: 0;
      right: 33px;
    }
  }

  .shipment-value {

    @include breakpoint(md) {
      width: 255px;
    }

    .info {
      margin-left: 10px;
    }
  }

  .quote-close {
    width: 30px;
    height: auto;
  }

  .slick-dotted.slick-slider {
    margin-top: 79px;
  }

  .slick-dots {
    bottom: auto;
    top: -44px;
    left: 0;
    max-width: 266px;
    margin: 0 auto;

    @include breakpoint(md) {
      width: 260px;
      //left: -18px;
      left: -40px;
    }

    li {
      width: 72px;
      justify-content: center;
      align-content: center;
      height: 12px;
      text-transform: uppercase;
      border-radius: 0;
      border: 0;
      text-indent: 0;
      font-size: 12px;
      line-height: 14px;
      //opacity: 0.2;
      transition: color 0.3s linear, opacity 0.3s linear;
      font-family: $montserrat-bold;
      color: $int-bright-blue-3;

      @include breakpoint(md) {
        width: 70px;
      }

      &:nth-of-type(2) {
        width: 89px;
        padding-left: 5px;

        @include breakpoint(md) {
          width: 70px;
        }
      }

      &:last-of-type {
        span {
          margin-left: 23px;
        }
      }

      &:not(:first-of-type) {
        &:before {
          content: '';
          display: block;
          height: 18px;
          border-left: 1px solid $int-medium-blue;
          position: absolute;
          left: -3px;
          top: 50%;
          transform: translateY(-50%);
          transition: none;

          @include breakpoint(md) {
            width: 70px;
          }
        }
      }

      &.slick-active {
        color: $int-white;
        opacity: 1;
      }


      &:hover {
        color: $int-white;
        opacity: 1;
        &:before {
          border-left: 1px solid $int-medium-blue;
        }
      }
    }
  }

  label.calendar-label {
    font-size: 12px;
    font-family: $roboto-regular;
    color: $int-white;
  }

  input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      box-shadow: none;
    }
  }

  input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date],
  input[type=datetime-local], input[type=tel], input[type=number], input[type=search-md], textarea.materialize-textarea, textarea, select {
    //border-bottom: none;
    background: #fff;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: $int-black;
    font-size: 12px;
    height: 40px;
    text-transform: capitalize;
    font-family: $roboto-regular;
    //border: none;
    margin-right: 4px;
    display: inline-block;
    transition: all 0.3s linear;
    box-shadow: none;
    border: 2px solid transparent;

    &:invalid {
        border: 2px solid $int-orange;
    }
  }

  select[disabled] {
    background-color: $int-medium-blue;
    color: $int-medium-blue-2;
  }

  input[type="text"]:disabled {
    background-color: $int-medium-blue !important;
    color: $int-medium-blue-2;
    //border: none;
    box-shadow: none;
  }

  input[type="text"]:disabled::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $int-medium-blue-2;
  }
  input[type="text"]:disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $int-medium-blue-2;
    opacity: 1;
  }
  input[type="text"]:disabled::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $int-medium-blue-2;
    opacity: 1;
  }
  input[type="text"]:disabled:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $int-medium-blue-2;
  }
  input[type="text"]:disabled::-ms-input-placeholder { /* Microsoft Edge */
    color: $int-medium-blue-2;
  }

  .description {
    //height: 40px;
    margin-bottom: 15px;
    //line-height: 40px;


    //@include breakpoint(md) {
    //  height: 80px;
    //  line-height: 12px;
    //  padding-top: 15px;
    //}
  }

  input.extra-small-width, select.extra-small-width {
    width: 56px;
  }

  input.small-width, select.small-width {
    width: 64px;
  }

  input.medium-width, select.medium-width {
    width: 155px;
  }

  input.medium-width2, select.medium-width2 {
    width: 186px;
  }
  input.medium-width3, select.medium-width3 {
    width: 143px;
  }

  input.large-width, select.large-width {
    width: 231px;
  }

  //textarea.large-width {
  //  width: 255px;
  //}

  .item-info {
    input {
      display: inline;

      &:nth-of-type(4), &:nth-of-type(5) {
        margin-bottom: 7px;
      }

      &:nth-of-type(3) {
        margin-right: 0;
      }
    }
  }

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $int-light-gray;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $int-light-gray;
    opacity: 1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $int-light-gray;
    opacity: 1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $int-light-gray;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $int-light-gray;
  }

  .reply-info {

    @include breakpoint(md) {
      width: 255px;
      padding-top: 30px;
    }

    //hr {
    //  margin-top: 30px;
    //  margin-bottom: 30px;
    //
    //  @include breakpoint(md) {
    //    width: 100%;
    //  }
    //}

    p {
      font-size: 18px;
      line-height: 24px;
      font-family: $montserrat-bold;
      color: $int-bright-blue-3;
    }
  }

  .submit-quote {
    margin-bottom: 76px;
    width: 150px;
    height: 50px;
    background: $int-bright-blue-2;
    color: $int-medium-blue;
    border: none;
    border-radius: 3px;
    font-size: 18px;
    text-transform: uppercase;
    font-family: $montserrat-bold;
    transition: color .3s linear;

    &:hover {
      color: $int-white;
    }
  }

  .name {
    .name-input {
      margin-top: 24px;
    }
  }

  .email {
    //border-top: 1px solid #0477F8;
    position: relative;

    @include breakpoint(md) {
      width: 255px;
    }

    .email-input {
      margin-bottom: 31px;
      text-transform: none;
    }
  }

  .truckload .standard-palletized {
    margin-top: 15px;

    @include breakpoint(md) {
      margin-top: inherit;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  //form.submitted {
  //  input[name="email"] {
  //    &:valid {
  //      & + .email-icon {
  //        background-image: url('/images/email-success.svg');
  //      }
  //    }
  //    &:invalid {
  //      & + .email-icon {
  //        background-image: url('/images/email-warning.svg');
  //      }
  //    }
  //  }
  //}

  //.email-icon {
  //  width: 25px;
  //  height: 25px;
  //  position: absolute;
  //  top: 32px;
  //  right: 25px;
  //  background-image: url('/images/email-enter.svg');
  //}

  .checkbox-container {
    position: relative;
  }

  [type="checkbox"] + label, [type="radio"] + label {
    font-weight: normal;
    padding-left: 25px;
    line-height: 26px;
    margin-bottom: 15px;
  }

  [type="checkbox"]:checked + label:before, [type="radio"]:checked + label:before {
    top: 3px;
    transform: none;
    background-color: $int-medium-blue-4;
    left: 0;
    right: auto;
  }

  .checkbox-label {
    color: white;
    font-size: 12px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 3px;
      margin-top: 2px;
      height: 15px;
      width: 15px;
      border-radius: 25px;
      border: 3px solid $int-white;
      background-color: $int-white;
      left: 0;
      right: auto;
    }
  }

  .loose-pieces {
    margin-top: 15px;
    @include breakpoint(md) {
     margin-top: inherit;
    }
  }

  .hazmat, .shipment-type, .stackable-content {
    [type="radio"] + label, [type="checkbox"] + label {
      height: 18px;
      line-height: 24px;
      &:before {
        //margin: 4px 4px 4px 0;
        margin: 0;
        padding: 0;
      }
    }
  }

  [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
    display: none;
  }

  .item-description {
    @include breakpoint(md) {
      width: 50%;
      width: 255px;
      display: inline-block;
    }
  }

  .hazmat {
    @include breakpoint(md) {
      width: 50%;
      display: inline-block;
      //display: block;
      float: right;
      margin-top: 55px;
      width: 255px;
    }

    input.unNumber {
      display: inline-block;
      width: 148px;

      @include breakpoint(md) {
        margin-right: 3px;
      }
    }

    .pg-dropdown-outer {
      display: inline-block;

      .pg-dropdown {
        display: inline-block;
        color: $int-light-gray;
        border-radius: 3px;
        //height: 40px;
        margin-right: 0;
        width: 79px;
      }
    }
  }

  .shipment-type {

    @include breakpoint(md) {
      float: right;
      margin-top: -35px;
      display: inline-block;
      width: 255px;
    }
  }

  .stackable-content {
    @include breakpoint(md) {
      display: inline-block;
      width: 255px;
    }
  }

  .nmfc {
    margin-top: 15px;
  }

  .F-Class-LTL {
    margin-right: 0 !important;
  }

  .over-dimensional {
    hr {
      padding-bottom: 15px;
    }

    label {
      display: block;
    }

    #odHeight {
      margin-right: 0;
    }

    @include breakpoint(md) {
      width: 255px;

      label {
        clear: both;
      }
    }
  }

  .f-class {
    display: inline-block;
    margin-right: 0;
  }

  .accessorials, .equipment {
    vertical-align: top;

    .equipment-button, .accessorials-button {
      font-size: 12px;
      border-radius: 0;
      background-color: $int-medium-blue;
      text-transform: none;
      text-align: left;
      margin: 0;
      box-shadow: none;
      position: relative;
      width: 320px;
      padding-left: 32.5px;
      padding-right: 32.5px;

      &:hover, &:focus {
        color: $int-white;
        background-color: $int-medium-blue !important;
      }

      @include breakpoint(md) {
        width: 300px;
        padding-left: 22.5px;
        padding-right: 22.5px;
      }

      .svg {
        content: '';
        width: 10px;
        height: 8px;
        position: relative;
        right: 3px;
        top: 10px;
        border: none;
        display: inline-block;
        margin: 0;
        float: right;

        path {
          fill: $int-white;
        }
      }
    }

    .incremental {
      display: inline-block;
      position: absolute;
      right: 50px;
      top: 14px;

      p {
        display: inline-block;
        font-family: $montserrat-regular;
        line-height: 17px;
        font-size: 25px;
      }

      a {
        display: inline-block;
        font-family: $montserrat-regular;
        line-height: 16px;
        font-size: 25px;
        color: $int-white;

        &.add {
          right: -39px;
          position: absolute;

          &:focus {
            outline: none;
          }
        }

        &.minus {
          right: 25px;
          top: -3px;
          position: absolute;

          &:focus {
            outline: none;
          }
        }
      }

      &.large {
        a {
          &.minus {
            right: 54px;
          }
        }
      }
    }

    .collapse {
      -webkit-transition: all .3s ease-out;
      -o-transition: all .3s ease-out;
      transition: all .3s ease-out;
      background-color: $int-bright-blue-2;
      //padding-bottom: 30px;
    }

    .selected-content {
      margin-left: 32.5px;
      margin-top: 8px;

      @include breakpoint(md) {
        margin-left: 22.5px;
      }

      .selected-items {
        position: relative;

        .selected-remove {
          display: inline-block;
          margin-right: 10px;
          position: absolute;
          top: 9px;

          img {
            height: 7px;
            width: auto;
          }
        }

        p {
          font-size: 14px;
          font-family: $roboto-regular;
          display: inline-block;
          padding-left: 20px;
        }
      }
    }

    .border {
      border-top: 1px solid $int-medium-blue;
      padding-top: 10px;

      &.first {
        border-top: none;
      }
    }

    .collapse, .collapse.collapsing {

      > ul {
        margin-bottom: 0;

        > li.primary-level {
          .primary-last {
            //input[type="radio"]:not(:checked) + label, input[type="checkbox"]:not(:checked) + label {
            //    margin-bottom: 30px;
            //}
            input[type="radio"]:checked + label, input[type="checkbox"]:checked + label {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .collapse-wrap {
      & > ul {
        padding-bottom: 30px;
        margin-bottom: 0;
      }
    }

    ul {
      background-color: $int-bright-blue-2;
      //padding-bottom: 30px;

      &.inner {
        margin: 0;

        li {
          margin: 0;
        }
      }

      li {
        //border-bottom: 1px solid $int-medium-blue;
        //padding-top: 10px;
        position: relative;
        margin-right: 32.5px;
        margin-left: 32.5px;

        @include breakpoint(md) {
          margin-left: 22.5px;
          margin-right: 22.5px;
        }

        //&:last-of-type {
        //    border-bottom: 0;
        //    padding-bottom: 50px;
        //
        //    //[type="radio"]:not(:checked) + label, [type="radio"]:checked + label,
        //    //.checked input[type="checkbox"]:not(:checked) + label, .checked input[type="checkbox"]:checked + label {
        //    //    border-bottom: none;
        //    //}
        //}

        &.underline {
          //border-bottom: 1px solid $int-medium-blue;
          padding-bottom: 10px;
        }

        input[type="number"] {
          width: 30px;
          font-size: 12px;
          height: 20px;
          position: absolute;
          right: 26px;
          float: right;
          padding: 0 5px;
          color: $int-black;
          vertical-align: middle;
          line-height: 17px;
          font-family: $montserrat-regular;
          border-bottom: 1px solid $int-medium-blue;
          margin-bottom: 5px;

          label {
            color: $int-white;
            vertical-align: middle;
            line-height: 17px;
            font-family: $roboto-regular;
            font-size: 12px;
            margin-right: 22.5px;
            //padding-left: 42.5px;

            //@include breakpoint(md) {
            //    margin-left: 22.5px;
            //    margin-right: 22.5px;
            //}
          }
        }

        //#etrack-bars-partial, #etrack-straps-partial, #etrack-bars-truckload, #etrack-straps-truckload {
        //  width: 30px;
        //}

        input[type="number"] + label {
          color: $int-white;
          vertical-align: middle;
          line-height: 17px;
          font-family: $montserrat-regular;
          font-size: 12px;
          padding-left: 42.5px;
          font-weight: normal;

          &.large {
            padding-left: 22px;
          }
        }

        input[type="checkbox"], input[type="radio"], input[type="radio"] + label:after {
          display: none;
        }

        [type="radio"] + label, [type="checkbox"] + label {
          position: relative;
          cursor: pointer;
          width: 100%;
          color: $int-white;
          font-size: 12px;
          vertical-align: middle;
          line-height: 17px;
          font-family: $montserrat-regular;
          //border-bottom: 1px solid $int-medium-blue;
          padding-left: 0;
          //margin-bottom: 5px;
          padding-bottom: 28px;
          margin-bottom: 0;

          &:before {
            content: "";
            background-color: $int-bright-blue-2;
            font-size: 12px;
            text-align: center;
            color: transparent;
            height: 16px;
            width: 16px;
            border-radius: 3px;
            right: 0;
            left: unset;
            margin-top: 0;
            top: 0;
            border: 1px solid $int-white;
            margin-right: 0;
          }

          &:after {
            display: none;
          }
        }

        &.primary-level.checked input[type="radio"] + label, .primary-level.checked input[type="checkbox"] + label {
          font-family: $montserrat-bold;
          //padding-left: 32.5px;
        }

        &.primary-level.checked input[type="checkbox"]:checked ~ label:before {
          color: #FFF;
          box-shadow: none;
          transition: all .2s ease-in-out;
          -webkit-transition: all .2s ease-in-out;
          background-image: url('/images/Check-box.svg');
          height: 16px;
          width: 16px;
          border-radius: 3px;
          border: 1px solid transparent;
          background-repeat: no-repeat;
          border: none;
        }

        &.secondary-level.checked input[type="radio"] + label, &.secondary-level.checked input[type="checkbox"] + label {
          padding-left: 22px;
          font-family: $montserrat-regular;
        }

        &.tertiary-level.checked input[type="radio"] + label, &.tertiary-level.checked input[type="checkbox"] + label {
          padding-left: 41px;
        }

        .checked input[type="checkbox"]:checked ~ label:before, [type="radio"]:checked ~ label:before {
          //background-color: #3F51B5;
          color: #FFF;
          box-shadow: none;
          transition: all .2s ease-in-out;
          -webkit-transition: all .2s ease-in-out;
          background-image: url('/images/Check-box.svg');
          height: 16px;
          width: 16px;
          border-radius: 3px;
          border: 1px solid transparent;
          background-repeat: no-repeat;
          border: none;
        }

        &:last-of-type {
          > ul {
            > li {
              &:last-of-type {
                border-bottom: none;
              }
            }
          }
        }
      }
    }

    [type="checkbox"]:not(:checked) + label, [type="radio"]:not(:checked) + label, .checkboxDisabled[disabled] + label {
      color: $int-medium-blue;

      &:before {
        border: 1px solid $int-medium-blue;
      }
    }

    input {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  .equipment {
    @include breakpoint(md) {
      display: inline-block;
      width: 300px;
      //margin-right: 27px;
    }
  }

  .accessorials {
    @include breakpoint(md) {
      display: inline-block;
      width: 300px;
    }
  }

  //radio button styling for the Stackable Section

  .stackable {
    p {
      font-size: 12px;
      font-family: $montserrat-regular;
    }

    @include breakpoint(md) {
      input[type="text"] {
        margin-bottom: 0;
      }
    }

    .radioDiv {
      width: auto;
      display: inline-block;

      [type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
        //background-color: $int-medium-blue;
        width: 80px;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        height: 40px;
        border-radius: 3px;
        line-height: 40px;
        font-size: 12px;
        font-family: $montserrat-bold;
        //color: $int-medium-blue-2;
        display: inline-block;
        transition: color .3s linear, background-color .3s linear;
        margin-right: 4px;
        margin-bottom: 15px;
        background-color: $int-white;
        color: $int-bright-blue-2;

        &:before, &:after {
          display: none;
        }

        &:hover {
          color: white;
          background-color: $int-bright-blue-2;
        }

        &:focus {
          color: $int-medium-blue;
        }
      }
      [type="radio"]:checked + label {
        background-color: $int-bright-blue-2;
        //color: $int-medium-blue;
        color: $int-white;
      }

      &:nth-of-type(3) {
        [type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
          margin-right: 0;
        }
      }

      .radio[disabled] + label {
        background-color: $int-medium-blue;
        color: $int-medium-blue-2;

        &:hover {
          background-color: $int-medium-blue;
          color: $int-medium-blue-2;
          cursor: not-allowed;
        }
      }
    }
  }

  //.partial {
  //  #stackableWeightPartial, #stackablePiecesPartial {
  //    margin-bottom: 0;
  //  }
  //}

  //*selectize styling*//
  .selectize-input {
    box-shadow: none;
  }

  .selectize-control.single .selectize-input {
    display: inline-block;
    font-family: $montserrat-regular;
    //letter-spacing: 1.2px;
    font-size: 12px;
    padding: 14px 10px 14px 10px;
    line-height: 24px;
    height: 24px;
    min-height: 0;
    transition: all .3s linear;

    &.dropdown-active:after {
      border: none !important;
    }

    &:after {
      margin-top: 4px;
      content: '';
      width: 10px;
      height: 8px;
      background-image: url('/images/dropdown-arrow.svg');
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      margin: 0;

    }

    &:focus {
      outline: none !important;
    }

    &.focus {
      box-shadow: none;
    }

  }

  .selectize-input.items.not-full.has-options.disabled.locked {
    background-color: $int-medium-blue !important;
    border: 2px solid $int-medium-blue;
    opacity: 1;
    transition: all .3s linear;

    input {
      &:after {
        opacity:.5;
        path {
          fill: $int-white;
        }
      }
    }
  }

  .pg-dropdown-outer .selectized {
    font-family: $montserrat-regular;
    font-size: 12px;
    text-transform: uppercase;
    width: 100% !important;
  }

  .selectize-control.selectize.single .selectize-input input {
    color: $int-bright-blue;
    font-size: 12px;
  }

  .selectize-control {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .selectize-dropdown {
    border-radius: 4px !important;
    border: none;
    top: 30px !important;
    box-shadow: none !important;

    .active {
      background-color: $int-white !important;
    }
  }

  .selectize input[type="text"] {

    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }

  .selectize-dropdown-content {
    padding: 0 !important;
  }

  .selectize-input > * {

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .selectize-control.single .selectize-input {
    display: inline;

    &.full {
      input {
        position: absolute !important;
      }
    }
  }

  .selectize-dropdown-content, .selectize-dropdown [data-selectable], .selectize-dropdown .optgroup-header, .form-group, .selectize option, .selectize-input, .selectize-input > input {
    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }

  .selectize-control.single .selectize-input {
    display: inline;
    height: 29px;
    line-height: 29px;
    border-radius: 3px !important;
  }

  .selectize-input > * {
    width: 278px !important;
    width: 58px !important;
  }

  .hazmat {
    .selectize-input.items.has-options.full.has-items {
      padding: 13px 11px 13px 11px;
    }

    .selectize-input.items.not-full.has-options.disabled.locked {
      padding: 13px 10px 13px 10px;

      &:after {
        opacity: .5;
        background-image: url('/images/dropdown-arrow-disabled.svg');
      }
    }

    .selectize-input > * {
      width: 52px !important;

      //@include breakpoint(md) {
      //    width: 55px !important;
      //}
    }

    .selectize-dropdown {
      width: 80px !important;
    }
  }

  .freight-class {
    .selectize-control.single .selectize-input {
      padding: 12px 11px 12px 11px;
      padding: 12px 0;
    }

    //.selectize-input.items.has-options.full.has-items {
    //  input {
    //    width: 165px !important;
    //    padding: 12px !important;
    //  }
    //}
    .selectize-input.items.has-options.not-full {
      input {
        width: 139px !important;
        padding: 12px !important;
      }
    }
    .selectize-input > * {
      width: 165px !important;
      padding-left: 12px !important;
    }

    .selectize-dropdown {
      width: 167px !important;
    }

    .selectize-dropdown-content {
      max-height: none;
    }
  }

  //*end selectize styling *//
}