/*********************
Panels Premium
**********************/

// Popout for collapsible 
.popout {
  border: none;
  box-shadow: none;
  a {
    color: #333;
  }
  a:hover {
    text-decoration: none;
  }
  .panel-group {
    box-shadow: none;
    > li {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      // transform: scaleX(.92);
      margin: 0 24px;
      transition: margin .35s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    }
    > li.active {
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
      margin: 16px 0;
      // transform: scaleX(1);
    }
    > .panel {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      // transform: scaleX(.92);
      margin: 0 24px;
      transition: margin .35s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    }
    > .active {
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
      margin: 16px 0;
      // transform: scaleX(1);
    }
  }
}   