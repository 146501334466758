//## Integrity Brand Colors
$int-yellow: #FFFF3C;
$int-nav-yellow: #FFFF02;
$int-tool-hover-yellow: #FDFF02;

$int-navy-blue: #252E6C;
$int-dark-blue: #21439C;
$int-dark-blue-2: #0431AF;
$int-dark-blue-3: #051D71;
$int-bright-blue: #2B7DE9;
$int-bright-blue-2: #001E72;
$int-bright-blue-3: #4BB2F0;
$int-bright-blue-4: #2BC4F3;
$int-bright-blue-5: #0277f8;
$int-bright-blue-6: #2BC4F2;
$int-bright-blue-7: #1F37FF;
$int-medium-blue: #4373B9;
$int-medium-blue-2: #0138FF;
$int-medium-blue-3: #3692F9;
$int-medium-blue-4: #0020DE;
$int-medium-blue-5: #0080FF;
$int-medium-blue-6: #0D30AF;
$int-medium-blue: #0477F8;
$int-light-blue: #0496F8;
$int-light-blue-2: #96D5FA;
$int-light-blue-3: #219FF4;
$int-light-blue-4: #68ADFB;
$int-light-blue-5: #4AB2EF;
$int-torquoise: #00A8AB;
$int-flat-blue: #10428C;
$int-teal: #53C3DE;

$int-white: #FFFFFF;
$int-black: #000;

$int-dark-gray: #404041;
$int-medium-gray: #707073;
$int-medium-gray-2: #8E8E8E;
$int-light-gray: #A3A3A3;
$int-light-gray-2: #B1B1B1;;
$int-xlight-gray: #E6E7E8;
$int-white-smoke: #F2F2F2;

$int-bright-green: #28CE30;
$int-bright-green-2: #1FBF63;

$int-bright-red: #F93543;

$int-orange: #F29034;


//## Integrity Brand Gradients

// Light Blue gradient colors
$int-gradient-light-blue-from: #0080FF;
$int-gradient-light-blue-to: #0496F8;   

//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

