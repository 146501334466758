.int-button {
    line-height: 60px;
    width: 200px;
    background: $int-navy-blue;
    color: $int-light-blue;
    border-radius: 3px;
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    transition: color 0.3s linear;
    font-family: $montserrat-bold;
    box-shadow: none;

    &:hover {
        color: $int-white;
    }
}

.secondary-button {
    background-color: $int-bright-blue-2;
    color: $int-bright-blue-3;
    height: 58px;
    width: 200px;
    border-radius: 3px;
    text-transform: uppercase;
    display: block;
    text-align: center;
    font-size: 18px;
    font-family: $montserrat-bold;
    transition: color 0.3s linear;
    margin: 0 auto;
    box-shadow: none;
    line-height: 50px;

    &:hover {
      color: $int-white;
      box-shadow: none;
    }
}