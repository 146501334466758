.services {
  .footer {
    z-index: 1;
  }
  #background {
    position: fixed;
    height: 100%;
    width: 100%;

    &.released {
      position: absolute;
    }
  }
  .gradient-1 {
    background-color: $int-dark-blue-2;
    background-image: none;
    transition: background-color 0.3s linear;
  }

  .row.services--section {
    margin-left: 0;
    margin-right: 0;
  }

  &--section {
    position: relative;
    height: 100vh;
    min-height: 100%;
  }

  &--service__types {
    background-color: $int-dark-blue-2;
    z-index: 1;

    .services--types__content{
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      padding-left: 0;
      padding-right: 0;
    }

    .services-swiper-background,
    .services-swiper-foreground,
    .services-swiper-middleground {
      height: 100vh !important;
      width: auto !important;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .services-swiper-background {
      z-index: 2;
    }
    .services-swiper-foreground {
      z-index: 6;
      pointer-events: none;
    }
    .services-scroll-down {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 35px;
      z-index: 6;
      bottom: 4.05vh;

      @include breakpoint(sm) {
        bottom: 5.25vh;
      }

      @include breakpoint(md) {
        bottom: 5.27vh;
      }
    }

    .section-header {
      max-width: 284px;
      width: 100%;
      margin: 0 auto;
      position: absolute;
      top: 25.4vh;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      text-align: center;

      @include breakpoint(sm) {
        width: 383px;
        max-width: none;
      }
      @include breakpoint(md) {
        width: 445px;
        top: 30.1vh;
      }
    }

    h1 {
      color: $int-yellow;
      font-size: 24px;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 12px;

      @include breakpoint(sm) {
        font-size: 36px;
        line-height: 36px;
      }

      @include breakpoint(md) {
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 27px;
      }
    }

    h2 {
      color: $int-bright-blue-4;
      font-size: 18px;
      line-height: 21px;
      max-width: 178px;
      width: 100%;
      margin: 0 auto;

      @include breakpoint(sm) {
        font-size: 24px;
        line-height: 24px;
        max-width: 390px;
      }

      @include breakpoint(md) {
        line-height: 30px;
        max-width: none;
      }
    }

    .slick-slider {
      z-index: 3;
      margin-bottom: 0;
    }
    .service-swiper {
      .swiper-slide {
        position: relative;
        height: 100vh;

        .slide-content {
          width: 290px;
          //this is the spacing of the .section-header from the top + height of .section-header + spacing for above the paragraph
          margin-top: calc(25.4vh + 78px + 15px);
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 0;
          position: relative;
          z-index: 4;

          @include breakpoint(sm) {
            width: 390px;
          }

          @include breakpoint(md) {
            width: 593px;
            margin-top: calc(30.1vh + 105px + 27px);
          }
        }

        p {
          color: $int-white;
          font-size: 14px;
          line-height: 21px;
          width: 260px;
          margin: 15px auto 0;
          text-align: center;

          @include breakpoint(sm) {
            width: 390px;
          }

          @include breakpoint(md) {
            width: 435px;
            font-size: 18px;
            line-height: 24px;
            margin-top: 27px;
          }
        }

        .int-button {
          color: $int-bright-blue-3;
          background-color: $int-bright-blue-2;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);

          &:active, &:focus {
            outline: none;
          }
        }
      }
      .slick-dots {
        bottom: 14.26vh;

        @include breakpoint(sm) {
          bottom: 14.84vh;
        }

        @include breakpoint(md) {
          bottom: 14.16vh;
        }

        li {
          margin: 0;
          width: 68.75px;
          justify-content: center;
          align-content: center;
          height: 23px;
          text-transform: uppercase;
          border-radius: 0;
          border: 0;
          text-indent: 0;
          font-size: 12px;
          line-height: 17px;
          color: $int-bright-blue-4;
          font-family: $montserrat-bold;
          border-bottom: solid 2px $int-light-blue;
          transition: color 0.3s linear, border 0.3s linear;

          &:hover {
            color: $int-yellow;
          }

          @include breakpoint(md) {
            width: 87.5px;
            font-size: 15px;
            line-height: 22px;
            height: 32px;
            border-bottom: solid 2.5px $int-light-blue;
          }

          &.slick-active {
            color: $int-yellow;
            border-bottom: solid 2px $int-yellow;

            @include breakpoint(md) {
              border-bottom: solid 2.5px $int-yellow;
            }
          }
        }
      }
    }
  }

  &--expect {
    background-color: $int-dark-blue-2;
    padding: 35px 0 0;
    position: relative;
    z-index: 1;

    @include breakpoint(sm) {
      height: 992px;
    }

    @include breakpoint(lg) {
      height: 768px;
    }

    @include breakpoint(xlg) {
      height: 1000px;
    }

    .services--expect__content{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    &__wrapper {
      max-width: 1200px;
      margin: 0 auto;
    }

    h2 {
      color: $int-yellow;
      text-align: center;
      font-size: 21px;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 30px;

      @include breakpoint(sm) {
        font-size: 36px;
        line-height: 40px;
      }

      @include breakpoint(lg) {
        margin-bottom: 52px;
      }
      @include breakpoint(xlg) {
        margin-bottom: 98px;
      }
    }

    .what-to-expect-swiper {
        .slick-dotted.slick-slider {
          margin-bottom: 0;
        }

        .swiper-wrapper {
          @include breakpoint(lg) {
            width: 832px;
            margin: 0 auto;
          }
          @include breakpoint(xlg) {
            width:980px;
          }
          @include breakpoint(xxlg) {
            width: 59.375vw;
            max-width: 1050px;
          }
        }

        .swiper-slide {
          padding: 76px 0 0;
          @include breakpoint(sm) {
            padding-top: 70px;
          }
          @include breakpoint(lg) {
            padding-top: 123px;
          }
          @include breakpoint(xlg) {
            padding-top: 143px;
          }

          .col-md-5 {
            @include breakpoint(lg) {
              padding-right: 0;
            }

            @include breakpoint(xxlg) {
              padding-left: 0;
            }
          }

          .col-md-7 {
            @include breakpoint(lg) {
              padding-right: 0;
              padding-left: 10px;
            }
          }

          h4 {
            color: $int-medium-blue;
            text-align: center;
            font-size: 16px;
            line-height: 19px;
            margin-top: 39px;
            margin-bottom: 18px;

            @include breakpoint(sm) {
              font-size: 24px;
              line-height: 29px;
              margin-top: 40px;
              margin-bottom: 22px;
            }
            @include breakpoint(lg) {
              text-align: left;
              font-size: 30px;
              line-height: 36px;
              width: 319px;
              margin-top: 41px;
              margin-bottom: 29px;
            }
            @include breakpoint(xlg) {
              margin-top: 59px;
            }
          }

          p {
            color: $int-white;
            text-align: center;
            font-size: 14px;
            line-height: 21px;
            width: 240px;
            margin: 0 auto;

            @include breakpoint(sm) {
              font-size: 16px;
              line-height: 24px;
              width: 405px;
            }

            @include breakpoint(lg) {
              text-align: left;
              width: 319px;
              margin-left: 0;
            }
          }

          .expect-animation {
            height: auto !important;
            margin: 0 auto;
            display: block;
            max-width: 403px;

            @include breakpoint(sm) {
              width: 403px !important;
              max-width: none;
            }

            @include breakpoint(md) {
              width: 100% !important;
              max-width: 600px;
            }

            @include breakpoint(lg) {
              width: 100% !important;
              max-width: none;
            }
            @include breakpoint(xlg) {
              width: 561px  !important;
            }

            @include breakpoint(xxlg) {
              margin-right: 0;
            }
          }
        }
        .slick-dots {
          top: 0;
          bottom: auto;

          li {
            width: 80px;
            justify-content: center;
            align-content: center;
            height: 28px;
            border-radius: 0;
            border: 0;
            text-indent: 0;

            span {
              text-transform: uppercase;
              display: block;
              font-size: 12px;
              line-height: 14px;
              color: $int-white;
              opacity: 0.2;
              font-family: $montserrat-bold;
              transition: color 0.3s linear, opacity 0.3s linear;

              @include breakpoint(sm) {
                font-size: 14px;
                line-height: 18px;
              }
              @include breakpoint(lg) {
                font-size: 18px;
                line-height: 24px;
              }
            }

            @include breakpoint(sm) {
              width: 100px;
              height: 36px;
              margin: 0 30px;
            }

            @include breakpoint(lg) {
              width: 115px;
              height: 44px;
            }

            &:not(:first-of-type) {
              &:before {
                content: '';
                display: block;
                height: 18px;
                border-left: 1px solid rgba(255, 255, 255, 1);
                position: absolute;
                left: -5px;
                top: 50%;
                transform: translateY(-50%);
                transition: none;

                @include breakpoint(sm) {
                  left: -30px;
                  height: 20px;
                }

                @include breakpoint(lg) {
                  height: 28px;
                }
              }
            }

            &.slick-active {
              span {
                color: $int-white;
                opacity: 1;
              }
            }

            &:hover {
              span {
                color: $int-white;
                opacity: 1;
              }
            }
          }
        }
      }
  }

  &--quote {
    margin-left: -15px;
    margin-right: -15px;
    background-image: linear-gradient(to bottom, $int-light-blue 37.18%, $int-bright-blue-3 100%);
    padding: 61px 0;
    text-align: center;

    @include breakpoint(sm) {
      padding: 60px 0;
    }
    @include breakpoint(lg) {
      padding: 120px 0;
    }
    @include breakpoint(xlg) {
      padding: 99px 0 140px;
    }

    .box-pattern {
      background-image: url("/images/services-bg.svg");
      margin: 0;
      background-size: 234px 183px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
    }

    .services--quote__content{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      width: 100%;

      @include breakpoint(lg) {
        width: 832px;
        margin: 0 auto;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @include breakpoint(xlg) {
        width: 980px;
      }
      @include breakpoint(xxlg) {
        width: 59.375vw;
        max-width: 1050px;
      }
    }

    .content {
      @include breakpoint(lg) {
        margin: 0 0 0 auto;
        width: 293px
      }
      @include breakpoint(xlg) {
        margin-left: 85px;
        margin-right: auto;
      }
      @include breakpoint(xxlg) {
        margin-left: 116px;
      }
    }

    h4 {
      text-align: center;
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 22px;
      color: $int-white;

      @include breakpoint(sm) {
        font-size: 36px;
        line-height: 40px;
        margin: 0px auto 21px;
        width: 220px;
      }
      @include breakpoint(lg) {
        margin-top: 47px;
        text-align: left;
        margin-left: 0;
      }
    }

    p {
      width: 260px;
      margin: 0 auto 52px;
      font-size: 14px;
      line-height: 21px;
      color: $int-white;

      @include breakpoint(sm) {
        font-size: 16px;
        line-height: 24px;
        width: 293px;
        margin-bottom: 28px;
      }
      @include breakpoint(lg) {
        text-align: left;
        margin-bottom: 33px;
      }
    }

    a {
      text-align: center;
      font-size: 14px;
      margin-bottom: 5px;
      font-family: $montserrat-bold;
      color: $int-white;

      &:hover, &:active, &:focus {
        outline: none;
      }

      @include breakpoint(lg) {
        font-size: 18px;
        line-height: 20px;
      }
    }

    .quote-button {
      text-align: center;
      font-size: 18px;
      background: $int-flat-blue;
      height: 60px;
      line-height: 60px;
      width: 200px;
      border-radius: 5px;
      border: solid 1px $int-flat-blue;
      color: $int-white;
      display: block;
      margin: 0 auto 57px;

      &:hover,
      &:focus {
        background: $int-medium-blue;
        border-color: $int-medium-blue;
      }

      @include breakpoint(sm) {
        margin-bottom: 68px;
      }

      @include breakpoint(lg) {
        margin-bottom: 0;
        margin-left: 0;
      }
    }

    a {
      @include transition(0.2s);
      text-transform: uppercase;
      text-decoration: none;
    }

    .left-block {
      @include breakpoint(lg) {
        padding: 0 0 0 15px;
        margin-left: 0;
        width: 50%;
      }
      @include breakpoint(xxlg) {
        margin-left: 0;
        padding-left: 15px;
      }
    }

    .image-content {
      @include breakpoint(xlg) {
        max-width: 420px;
      }
    }

    img {
      width: 246px;
      margin: 0 auto 20px;
      display: block;

      @include breakpoint(sm) {
        width: 420px;
        display: block;
        margin: 0 auto 28px;
      }

      @include breakpoint(xlg) {
        margin-right: 0;
      }
      @include breakpoint(xxlg) {
        margin-right: auto;
      }
    }
  }

  #one,
  #two,
  #three,
  #four{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s linear;
    overflow-x: hidden;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}