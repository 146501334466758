h1, h2, h3, h4, h5 {
    font-family: $montserrat-bold;
}

p {
    font-family: $montserrat-regular;
}

.container-fluid.int-cta {
    background: $int-dark-blue;
}
.int-cta h1 {
    color: $int-yellow;
    font-size: $int-headline;
}
.int-cta p {
    color: $int-white;
    font-size: $int-copy;
}
.int-cta > * {
    text-align: center;

    @media (min-width: $screen-md-min) {
        text-align: left; 
    }
}
