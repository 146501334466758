/** Gradient **/
@mixin gradient($from, $to) {
    /* fallback/image non-cover color */
    background-color: $from;

    /* Firefox 3.6+ */
    background-image: -moz-linear-gradient($from, $to);

    /* Safari 4+, Chrome 1+ */
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));

    /* Safari 5.1+, Chrome 10+ */
    background-image: -webkit-linear-gradient($from, $to);

    /* Opera 11.10+ */
    background-image: -o-linear-gradient($from, $to);
}

/** Breakpoints **/
@mixin breakpoint($class) {
    @if $class == xs {
        @media (min-width: 0) { @content; }
    }

    @else if $class == sm {
        @media (min-width: 544px) { @content; }
    }

    @else if $class == md {
        @media (min-width: 768px) { @content; }
    }

    @else if $class == lg {
        @media (min-width: 992px) { @content; }
    }

    @else if $class == xlg {
        @media (min-width: 1200px) { @content; }
    }

    @else if $class == xxlg {
        @media (min-width: 1600px) { @content; }
    }

    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg, xlg, xxlg";
    }
}

/** Transitions **/
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}
