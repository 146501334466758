/*********************
  Galleries
**********************/

// Tiled Gallery
.tiled-gallery {
    .col-md-6,
    .col-md-3 {
        padding: 0;
    }
    .tile {
        height: 300px;
        margin: 0;
        position: relative;
        z-index: 1;
        a {
            width: 100%;
            height: 100%;
        }
        .title {
            position: absolute;
            color: #fff;
            background-color: $rgba-stylish-strong;
            bottom: 0;
            left: 0;
            padding: 10px 10px 10px 30px;
            margin: 0;
            z-index: 2;
            .fa {
                margin-left: 5px; 
            }
            span {
                font-size: 23px;
            }
        }
    }
}

.portfolio .tile {
    background-repeat: no-repeat;
    background-size: cover;
}

.elegant-gallery {
    .card-content {
        padding: 2rem;
        h4 {
            text-align: left;
            padding-bottom: 15px;
            border-bottom: 1px solid #666;
        }
        h5 {
            text-align: right;
            margin-top: 1em;
        }
        .fa {
            font-size: 0.8em;
            margin-left: 8px;
        }
    }
}