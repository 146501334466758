.case-study {

    .page-wrapper {
        overflow-x: hidden;
    }

    > .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    .ind-casestudy {
        h1, h2, h3, h5, h5, h6, p {
            margin: 0;
        }
    }

    .ind-casestudy--gradient-1 {
        //background-image: linear-gradient(#0050BC 13%, $int-light-blue 37%, $int-dark-blue-2 46%);
        //z-index: 1;
        //position: absolute;
        //width: 100vw;
        ////height: 624px;
        //height: 562px;
        //transition: height 0.5s linear;

        //@include breakpoint(sm){
        //    height: 960px;
        //}

        //@include breakpoint(md){
        //    background-image: linear-gradient(#0050BC 39%, #0496F8 62%, #0431AF 78%);
        //    height: 1325px;
        //}

        //@include breakpoint(md){
        //    height: 1363px;
        //}
    }


    .ind-casestudy--hero,
    .casestudy-content-container,
    .ind-casestudy--secretsauce {
        text-align: center;

        h2, h3 {
            //color: $int-light-blue;
            color: $int-medium-blue-3;
        }

        p {
            font-size: 14px;
            line-height: 18px;

            @include breakpoint(sm){
                font-size: 18px;
                line-height: 30px;
            }
        }
    }

    .ind-casestudy--hero {
        position: relative;
        height: 120vw;
        max-height: 562px;
        background-image: linear-gradient(#0050BC 13%, $int-light-blue 37%, $int-dark-blue-2 46%);
        background-size: auto 562px;

        @include breakpoint(sm){
            height: 115vw;
            max-height: 960px;
            background-size: auto 960px;
        }

        @include breakpoint(md){
            height: 1325px;
            max-height: none;
            background-image: linear-gradient(#0050BC 39%, #0496F8 62%, #0431AF 78%);
            background-size: auto 1325px;
        }
        @include breakpoint(lg){
            height: 1363px;
            background-size: auto 1363px;
        }

        .hero-content {
          width: 205px;
          margin: 28px auto 0;

            @include breakpoint(sm){
              width: 484px;
              margin-top: 49px;
            }
            @include breakpoint(md){
                width: 543px;
            }
            @include breakpoint(lg){
                width: 725px;
            }

            h1, h2 {
                font-size: 21px;
                line-height: 25px;

                @include breakpoint(sm){
                    font-size: 30px;
                    line-height: 36px;
                }
                @include breakpoint(md){
                    font-size: 36px;
                    line-height: 43px;
                }
                @include breakpoint(lg){
                    font-size: 48px;
                    line-height: 64px;
                }
                @include breakpoint(xlg){
                    font-size: (48/16) + em;
                }
            }

            h1 {
                color: $int-yellow;
            }

            h2 {
                color: $int-light-blue;
                margin-top: 9px;

                @include breakpoint(sm){
                    margin-top: 14px;
                }
                @include breakpoint(lg){
                    margin-top: 10px;
                }
            }
        }

        p {
            color: $int-medium-gray;
            font-size: 14px;
            line-height: 18px;

            @include breakpoint(sm){
                font-size: 18px;
                line-height: 30px;
            }
            //@include breakpoint(lg){
            //    font-size: 21px;
            //    line-height: 36px;
            //}
        }
    }

    .ind-casestudy--hero__divider {
        position: absolute;
        top: 120px;
        width: 237%;
        left: -68%;
        z-index: 3;

        @include breakpoint(sm){
            top: 195px;
        }

        @include breakpoint(md){
            top: 500px;
            width: 2373px;
            left: calc(50vw - (618px * 2));
        }
        @include breakpoint(lg){
            top: 538px;
        }
    }

    .ind-casestudy--hero__content {
        padding-top: 40px;
        width: inherit;
        position: relative;
        z-index: 3;
        margin: 0;

        @include breakpoint(sm){
            padding-top: 95px;
            width: 100%;
        }

        h3, h4 {
            font-size: 14px;
            line-height: 18px;

            @include breakpoint(sm){
                font-size: 18px;
                line-height: 24px;
            }
        }

        h3 {
            color: $int-light-blue;
        }
        h4 {
            color: $int-light-gray;
        }
    }

    .study-content-container {
        background-color: $int-dark-blue-2;

        .toggle-hide{
            display: none;

            &.show-content {
                + .casestudy-nav-container {
                    padding-top: 96px;
                }
            }

            @include breakpoint(md) {
                display: block;
            }
        }
    }

    .casestudy-content-container {
        .casestudy-content-wrapper {
            margin: 0 auto;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            max-width: 715px;
            padding-left: 30px;
            padding-right: 30px;

            @include breakpoint(sm) {
                padding-left: 50px;
                padding-right: 50px;
            }

            @include breakpoint(md) {
                width: 93.1vw;
                max-width: 744px;
            }

            h2 {
                font-size: 21px;
                line-height: 25px;

                @include breakpoint(sm) {
                    font-size: 24px;
                }

                @include breakpoint(md) {
                    font-size: 30px;
                    line-height: 36px;
                }

                @include breakpoint(lg) {
                    font-size: 36px;
                    line-height: 40px;
                }
            }

            p {
                color: $int-white;
                line-height: 21px;
                margin-bottom: 21px;
                font-size: 14px;

                @include breakpoint(sm) {
                    line-height: 30px;
                    font-size: 18px;
                    margin-bottom: 30px;
                }

                @include breakpoint(lg) {
                    font-size: 21px;
                    line-height: 36px;
                  margin-bottom: 36px;
                }

            }

            .casestudy-title {
                max-width: 80%;
                margin: 0 auto;

                @include breakpoint(sm){
                    max-width: none;

                }
            }

            .casestudy-content {
                margin-top: 35px;

                @include breakpoint(md){
                  margin-top: 63px;
                }

              @include breakpoint(md){
                margin-top: 61px;
              }
            }
        }
    }

    .ind-casestudy--hero__whitebg {
        background: $int-white;
        border: solid 0 $int-white;
        border-radius: 10px;
        padding: 20px 15px 0;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 23px auto 0;
        width: 260px;
        opacity: 1;
        top:0;

        @include breakpoint(sm){
            width: 400px;
            margin-top: 84px;
            padding: 30px 30px 0;
        }

        @include breakpoint(md){
            width: 615px;
            padding: 47px 45px 0;
            margin-top: 66px;
        }

        @include breakpoint(lg){
            width: 800px;
        }

        .pull-quote,
        .attribution {
            @include breakpoint(md){
                width: 70%;
                text-align: left;
            }
        }

        .pull-quote {
            width: 222px;
            margin: 19px auto 9px;

            @include breakpoint(sm){
                width: 331px;
                margin: 15px auto 27px;
            }
            @include breakpoint(md){
                width: 359px;
                margin: 0 auto 23px 0;
            }
            @include breakpoint(lg){
                width: 480px;
                margin: 0 auto 31px 0;
            }
        }

        .attribution {
            h3 {
                @include breakpoint(lg){
                    display: inline-block;
                }
            }
            h4 {
                margin: 0 auto 10px;
                font-family: $montserrat-regular;

                @include breakpoint(sm){
                    margin-bottom: 30px;
                }
                @include breakpoint(md){
                    margin-bottom: 38px;
                }
                @include breakpoint(lg){
                    display: inline-block;
                }
            }
        }

        .casestudy-avatar {
            align-self: center;
            height: 121px;
            overflow: hidden;

            @include breakpoint(sm){
                height: 196px;
            }

            @include breakpoint(md){
                height: 252px;
                position: absolute;
                right: 28px;
                bottom: 0;
            }

            img {
                height: 128px;
                width: auto;

                @include breakpoint(sm){
                    height: 100%;
                }
            }
        }
    }

    .ind-casestudy--hero__link {
        //height: 90px;
        height: 28px;
        display: flex;
        justify-content: center;
        opacity: 1;
        transition: opacity 0.5s linear;

        &.hide-button {
            opacity: 0;
        }

        @include breakpoint(md){
            display: none;
        }

        a {
            text-transform: uppercase;
            font-size: 12px;
            line-height: 24px;
            margin-top: 4px;
            color: $int-light-blue;
            font-family: $montserrat-bold;

            &:hover, &:focus &:active {
                text-decoration: none;
                outline: none;
            }

            @include breakpoint(sm){
                font-size: 14px;
                margin-top: 37px;
            }
        }
    }

    .ind-casestudy--secretsauce {
        top: 250px;

        @include breakpoint(lg){
            top: 500px;
        }

        @include breakpoint(xlg){
            top: 500px;
        }
    }

    .ind-casestudy--secretsauce__content {
        .secretsauce-title {
            margin-top: 41px;

            @include breakpoint(sm) {
                margin-top: 71px;
            }

            @include breakpoint(md) {
                margin-top: 88px;
            }

            @include breakpoint(lg) {
                margin-top: 74px;
            }

            h2 {
                font-size: 21px;
                line-height: 32px;

                @include breakpoint(sm) {
                    font-size: 30px;
                }

                @include breakpoint(lg) {
                    font-size: 36px;
                }
            }
        }

        .secretsauce-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 49px;

            @include breakpoint(sm) {
                width: 163px;
                margin-top: 71px;
                text-align: center;
                display: inline-block;
            }
            @include breakpoint(md) {
                 width: 200px;

            }

            @include breakpoint(lg) {
                width: 259px;

            }

            h5 {
                margin-top: 29px;
                font-size: 12px;
                line-height: 14px;
                color: $int-white;
                text-transform: uppercase;

                @include breakpoint(sm) {
                    margin-left: auto;
                    margin-right: auto;
                  margin-top: 22px;
                }

                @include breakpoint(md){
                    font-size: 16px;
                    line-height: 21px;
                    margin-top: 26px;
                }
            }

            img {
                width: 150px;
                height: auto;

                @include breakpoint(sm){
                    width: 100px;
                }
                @include breakpoint(md){
                    width: 113px;
                }
            }
        }
    }

    .casestudy-nav-container {
        display: flex;
        justify-content: center;
        align-items: center;
        //padding: 96px 15px 80px;
        padding: 65px 15px 80px;
        width: 310px;
        margin: 0 auto;
        background-color: $int-dark-blue-2;
        transition: padding-top 0.5s linear;
        @include breakpoint(sm){
            //padding-bottom: 71px;
            width: 374px;
        }
        @include breakpoint(md){
            //padding-bottom: 80px;
            width: 503px;
            //max-width: none;
        }

        .nav-avatar, .story-nav {
            flex: 1;
            text-align: center;
            display: flex;
            position: relative;

            p {
                color: $int-medium-blue-3;
                text-decoration: none;
                font-family: $montserrat-bold;
                font-size: 12px;
                line-height: 38px;
                text-transform: uppercase;
                transition: color 0.5s linear;

                &:first-of-type {
                    padding: 0;
                }

                &:last-of-type {
                    padding: 0;
                }



                @include breakpoint(sm){
                    font-size: 15px;
                    line-height: 51px;
                }

                @include breakpoint(md){
                    font-size: 18px;
                    line-height: 70px;
                }
            }
        }

        .story-nav {

            &.prev {
                p {
                    padding-left: 23px;
                    padding-right: 27px;
                }
                @include breakpoint(md){
                    p {
                        padding-left: 31px;
                        padding-right: 47px;
                    }
                }

            }

            &.next {
                p {
                    padding-right: 9px;
                    padding-left: 28px;
                }
                @include breakpoint(md){
                    p {
                        padding-right: 0px;
                        padding-left: 49px;
                    }
                }

            }

            &:hover {
                p {
                    color: $int-white;
                }
            }
        }

        .nav-avatar {
                height: 30px;
                width: auto;
                top: 8px;

            &.left {
                padding-left: 10px;
                @include breakpoint(md){
                    padding-left: 10px;
                }

            }

            &.right {
                padding-left: 3px;
                @include breakpoint(md){
                    padding-left: 22px;
                }

            }

                @include breakpoint(sm){
                    height: 40px;
                }

                @include breakpoint(md){
                    height: 55px;
                }
        }

        .decoration {
            flex: 0;
            text-align: center;
            .svg {
                width: 23px;

                circle {
                    fill: rgb(54, 146, 249);
                    transition: fill 0.5s linear;
                }

                &:hover {
                    circle {
                        fill: $int-white;
                    }
                }

                @include breakpoint(sm){
                    width: 31px;
                }
                @include breakpoint(md){
                    width: 43px;
                }
            }
        }
    }

    //.ind-casestudy--navigation__blueoverlay {
    //    height: 30px;
    //    width: 30px;
    //  display: none;
    //
    //    background: rgba($int-light-blue, 0.75);
    //    border-radius: 50%;
    //    border: solid 1px rgba($int-light-blue,0.75);
    //
    //    position: absolute;
    //    top: 0;
    //    left: 50%;
    //    right: 50%;
    //    transform: translateX(-50%);
    //
    //    margin: 0 auto;
    //
    //
    //    @include breakpoint(sm){
    //        height: 55.5px;
    //        width: 55.5px;
    //    }
    //}
}