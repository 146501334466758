//@import url( 'https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700');

//## Font and size
$int-subhead: 1.2em;
$int-headline: 1.7em;
$int-copy: 1.2em;
$int-button: 1.5em;

@import "../modules/all";

html {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  //
  //::-webkit-scrollbar {
  //    -webkit-appearance: none;
  //    width: 7px;
  //}
  //
  //::-webkit-scrollbar-thumb {
  //    border-radius: 4px;
  //    background-color: rgba(0, 0, 0, .5);
  //    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  //}
}

body,
html {
  font-family: $montserrat-regular;
  font-size: 16px;
}

body {
  -webkit-overflow-scrolling: auto;
}

:focus {
  outline: none;
}

//## Background Colors
.int-background__navy {
  background-color: $int-dark-blue;
}

.int-background--gradient__light {
  @include gradient($int-gradient-light-blue-from, $int-gradient-light-blue-to);
}

.int-background--mountain__spacer {
  height: 220px;
}

//## Gradients
.gradient-1 {
  @include gradient(#0431AF, #0477F8);
}

.gradient-2 {
  @include gradient($int-dark-blue, $int-light-blue);
}

.info-modal {
  display: block;
  z-index: 1002;
  height: auto;
  width: 255px;
  background-color: $int-white;
  //position: absolute;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  //visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  .modal-close {
    position: absolute;
    right: 10px;
    top: 10px;

    .svg {
      height: 25px;
      width: auto;

      circle {
        fill: $int-light-gray;
      }

      polygon {
        fill: $int-white;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 20px;
    font-family: $montserrat-regular;
    padding: 50px 15px 30px 15px;
    color: $int-medium-blue;
    text-align: center;
  }
}

//.calculator-modal {
//  //display: block;
//  //z-index: 1002;
//  //height: auto;
//  ////width: 255px;
//  //background-color: $int-white-smoke;
//  ////position: absolute;
//  //position: fixed;
//  //top: 50%;
//  //left: 50%;
//  //transform: translate(-50%, -50%);
//  //border-radius: 4px;
//  ////visibility: hidden;
//  //opacity: 0;
//  //pointer-events: none;
//  //
//  //&.visible {
//  //  opacity: 1;
//  //  pointer-events: auto;
//  //}
//  //
//  //.modal-close {
//  //  position: absolute;
//  //  right: 10px;
//  //  top: 10px;
//  //  right: 6px;
//  //  top: 0;
//  //
//  //  .svg {
//  //    height: 24px;
//  //    width: auto;
//  //
//  //    circle {
//  //      fill: $int-light-gray;
//  //    }
//  //
//  //    polygon {
//  //      fill: $int-white;
//  //    }
//  //  }
//  //}
//
//  h4 {
//    color: $int-bright-blue-5;
//    font-family: $montserrat-regular;
//    font-weight: 500;
//    font-size: 8px;
//    margin: 0;
//  }
//
//  &.currency-converter-modal {
//    padding: 7px 6px;
//    input, select {
//      border: 1px solid $int-bright-blue-5;
//      border-radius: 2px;
//      background-color: $int-white-smoke;
//      font-size: 5px;
//    }
//  }
//
//  &.density-calculator-modal, &.cubic-feet-calculator-modal {
//    padding: 7px 6px;
//    .form-group {
//      display: inline-block;
//      margin-bottom: 7px;
//      &:first-of-type {
//        margin-right: 2px;
//      }
//      label {
//        font-family: $montserrat-regular;
//        font-weight: 500;
//        color: $int-bright-blue-5;
//        font-size: 5px;
//      }
//      input {
//        font-family: $montserrat-regular;
//        border: 1px solid $int-bright-blue-5;
//        border-radius: 2px;
//        background-color: $int-white-smoke;
//        color: $int-bright-blue-5;
//        font-size: 5px;
//        font-weight: 500;
//        width: 55px;
//        height: auto;
//        margin-bottom: 0;
//        padding: 5px 6px;
//
//        &:focus {
//          box-shadow: none;
//        }
//
//        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
//          color: $int-bright-blue-5;
//        }
//        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
//          color: $int-bright-blue-5;
//          opacity: 1;
//        }
//        &::-moz-placeholder { /* Mozilla Firefox 19+ */
//          color: $int-bright-blue-5;
//          opacity: 1;
//        }
//        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
//          color: $int-bright-blue-5;
//        }
//        &::-ms-input-placeholder { /* Microsoft Edge */
//          color: $int-bright-blue-5;
//        }
//      }
//    }
//    button {
//      background-color: $int-bright-blue-5;
//      color: $int-white;
//      font-family: $montserrat-bold;
//      font-size: 7px;
//      display: block;
//      width: 100%;
//      margin: 0;
//      box-shadow: none;
//      height: 16px;
//      line-height: 7px;
//
//      &:hover {
//        background-color: $int-bright-blue-5;
//      }
//    }
//  }
//}

.casestudy-scroller {
  svg {
    display: inline-block;
    width: 100%;
    height: auto;
  }
}
