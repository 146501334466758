//.calendar-div {

    .daterangepicker {
        border: none;
        padding: 0;
        box-shadow: none;
        background-color: $int-medium-blue-2;
        width: 300px;
        height: 380px;
        //position: absolute !important;
        //left: 50% !important;
        //top: 50% !important;
        //margin: 0 auto;
        position: relative;
        top: -15px;
        left: -21px;

        @include breakpoint(md) {
           position: absolute;
            top: -15px;
            left: auto;
            right: 0;
            height: 300px;
        }

        &:before, &:after {
            top: 0;
            border: none;
            display: none;
        }

        .daterangepicker_input {
            display: none
        }

        //&.opensembed {
        //    position: relative;
        //    top: auto;
        //    left: auto;
        //    float: none;
        //    z-index: auto;
        //    //height: 331px;
        //    //width: 100%;
        //    width: 300px;
        //    height: 380px;
        //
        //    > .calendar,
        //    > .ranges {
        //        display: inline-block;
        //        vertical-align: top;
        //    }
        //}
        .calendar {
            //width: 100%;
            max-width: none;
            width: 300px;
            height: 380px;

            @include breakpoint(md) {
                height: 300px;
                //width: 275px;
                width: 241px;
            }

            .calendar-table {
                background-color: $int-medium-blue-2;
                border: none;
            }

            thead {
                background-color: $int-medium-blue-2;
            }

            th {
                font-size: 15px;
                //line-height: 18px;
                line-height: 31px;
                font-family: $roboto-regular;
                color: $int-white;

                @include breakpoint(md) {
                    line-height: 24px;
                }

                &.month {
                    text-transform: uppercase;
                    //line-height: 58px;
                    padding-top: 15px;
                    font-size: 16px;

                    @include breakpoint(md) {
                        padding: 15px 5px 0 5px;
                    }
                }
            }
            td {
                font-size: 15px;
                //line-height: 18px;
                font-family: $roboto-regular;
                color: $int-white;
                //line-height: 31px;
                line-height:28px;

                @include breakpoint(md) {
                    line-height: 22px;
                }

                &.off {
                    background-color: $int-medium-blue-2;
                    color: $int-white;
                    border-radius: 0;
                }

                &.in-range {
                    color: $int-white;
                    background-color: rgba(0, 30, 114, .6);
                    border-radius: 50%;
                }

                &.active {
                    background-color: $int-bright-blue-2;
                    color: $int-white;
                    border-radius: 0;
                    border-radius: 50%;
                }

                &:hover {
                    background-color: $int-bright-blue-2;
                    border-radius: 50%;
                }
            }

            &.left {
                margin: 0;
                //border: 1px solid $int-bright-blue;
                border-radius: 4px;

              @include breakpoint(md) {
                margin-left: 17px;
              }

                .calendar-table {
                    padding: 0;
                    table {
                        max-width: 280px;
                        margin: 0 auto;
                    }
                }
            }

            &:after {
                content: 'Click the first and last date';
                font-family: $roboto-regular;
                font-size: 14px;
                color: $int-bright-blue-3;
                padding-left: 15px;
            }
        }

        .prev.available,
        .next.available {
            background-color: $int-bright-blue-3;
            transition: background-color .25s linear;
            -webkit-mask:  url('/images/calendar-cheveron-arrow.svg') no-repeat 50% 50%;
            mask: url('/images/calendar-cheveron-arrow.svg') no-repeat 50% 50%;
            position: absolute;
            height: 20px;
            padding: 0;
            z-index: 2;
            top: 22px;
            min-width: 24px;
            mask-size: 18px auto;
            -webkit-mask-size: 18px auto;

            @include breakpoint(md) {
                top: 17px;
            }

            .glyphicon:before {
                display: none;
            }

            &:hover {
                background-color: $int-white;
            }
        }
        .prev.available {
            left: 13px;
            transform: rotate(90deg);
            @include breakpoint(md) {
                left: 25px;
            }

        }

        .next.available {
            //transform: rotate(180deg);
            //left: 239px;
            //right: 13px;
            right: 30px;
            transform: rotate(-90deg);
            @include breakpoint(md) {
                right: 64px;
        }
        }


        .opensright {
            :before {
                display: none;
            }
        }
    }
//}
