/*********************
  Variables
**********************/


// === VARIABLES PART 1 ===//

/*** Tooltip ***/

$tooltip-background-color: #700;
$tooltip-text-color: #FFF;
$tooltip-font-size: 1.6rem;
$tooltip-font-size-large: 2.0rem;

 
/* ANIMATION */ 
$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1) !default;


 
/*** Colors ***/
$primary-color: color("materialize-red", "lighten-2") !default;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;

$secondary-color: color("teal", "lighten-1") !default;
$success-color: color("green", "base") !default;
$error-color: color("red", "base") !default;
$link-color: color("light-blue", "darken-1") !default;

/*** Badges ***/
$badge-bg-color: $primary-color !default;

/*** Buttons ***/
$button-bg-color-disabled: #DFDFDF !default;
$button-color: $secondary-color !default;
$button-color-disabled: #9F9F9F !default;
$button-color-flat: #343434 !default;
$button-color-raised: #fff !default;
$button-floating-size: 37px !default;
$button-height: 36px !default;
$button-font-size-shared: 1.3rem !default;
$button-large-icon-font-size: 1.6rem !default;
$button-line-height: 31px !default;

/*** Cards ***/
$card-padding: 20px !default;
$card-bg-color: #fff !default;
$card-link-color: color("orange", "accent-2") !default;
$card-link-color-light: lighten($card-link-color, 20%) !default;

/*** Collapsible ***/
$collapsible-height: 3rem !default;
$collapsible-header-color: #fff !default;
$collapsible-border-color: #ddd !default;

/*** Dropdown ***/
$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: $primary-color !default;

/*** Fonts ***/
$roboto-font-path: "../font/roboto/" !default;
$icons-font-path: "../font/material-design-icons/" !default;

/*** Forms ***/
// Text Inputs + Textarea
$input-border-color: color("grey", "base") !default;
$input-bg-color: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $success-color !default;
$input-focus-color: $primary-color !default;
$label-font-size: .8rem !default;
$input-disabled-color: rgba(0,0,0, .26) !default;
$input-disabled-solid-color: #BDBDBD !default;

// Radio Buttons
$radio-fill-color: $primary-color !default;
$radio-empty-color: #5a5a5a !default;

// Switches
$switch-bg-color: $primary-color !default;
$switch-checked-lever-bg: desaturate(lighten($primary-color, 25%), 25%) !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: #818181 !default;

// Date Picker
$datepicker-weekday-bg: darken($primary-color, 7%) !default;
$datepicker-date-bg: $primary-color !default;
$datepicker-year: rgba(255, 255, 255, .4) !default;
$datepicker-focus: rgba(0,0,0, .05) !default;
$datepicker-selected: $primary-color !default;
$datepicker-selected-outfocus: desaturate(lighten($primary-color, 35%), 15%) !default;

 
/*** Global ***/
// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
 
// Grid Variables
$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;

/*** Navbar ***/
$navbar-height: 64px !default;
$navbar-height-mobile: 56px !default;
$navbar-font-color: #fff !default;
$navbar-brand-font-size: 2.1rem !default;

/*** SideNav ***/
$sidenav-bg-color: #fff !default;
$sidenav-padding-right: 15px !default;

/*** Photo Slider ***/
$slider-bg-color: color('grey', 'base') !default;
$slider-bg-color-light: color('grey', 'lighten-2') !default;
$slider-indicator-color: color('green', 'base') !default;

/*** Tabs ***/
$tabs-underline-color: $primary-color-light !default;
$tabs-text-color: $primary-color !default;
$tabs-bg-color: #fff !default;

/*** Tables ***/
$table-border-color: #d0d0d0 !default;
$table-striped-color: #f2f2f2 !default;

/*** Toasts ***/
$toast-height: 48px !default;
$toast-color: #323232 !default;
$toast-text-color: #fff !default;

/*** Typography ***/
$off-black: rgba(0, 0, 0, 0.87) !default;
// Header Styles
$h1-fontsize: 4.2rem !default;
$h2-fontsize: 3.56rem !default;
$h3-fontsize: 2.92rem !default;
$h4-fontsize: 2.28rem !default;
$h5-fontsize: 1.64rem !default;
$h6-fontsize: 1rem !default;

// Footer
$footer-bg-color: $primary-color !default;

// Flowtext
$range : $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;

/*** Collections ***/
$collection-border-color: #e0e0e0 !default;
$collection-bg-color: #fff !default;
$collection-active-bg-color: $primary-color !default;
$collection-active-color: lighten($primary-color, 55%) !default;
$collection-hover-bg-color: #ddd !default;

/* Progress Bar */
$progress-bar-color: $primary-color !default;


// === VARIABLES PART 2 ===//

// material icons path
$material-font-path: "../fonts" !default;

// Material colors palette
$red: #F44336 !default;
$pink: #E91E63 !default;
$purple: #9C27B0 !default;
$deeppurple: #673AB7 !default;
$indigo: #3F51B5 !default;
$lightblue: #03A9F4 !default;
$cyan: #00BCD4 !default;
$teal: #009688 !default;
$lightgreen: #8BC34A !default;
$lime: #CDDC39 !default;
$lightyellow: #FFEB3B !default;
$orange: #FF9800 !default;
$deeporange: #FF5722 !default;
$grey: #9E9E9E !default;
$bluegrey: #607D8B !default;
$brown: #795548 !default;
$lightgrey: #ECECEC !default;

// Bootstrap shades
$primary: #4285F4 !default;
$success: #00c853 !default;
$info: $lightblue !default;
$warning: $deeporange !default;
$danger: #d32f2f !default;

// Typography elements for Material
$darkbg-text: rgba(255,255,255,0.84);
$lightbg-text: rgba(0,0,0,0.84);
$icon-color: rgba(0,0,0,0.5);


// Bootstrap variables
$btn-default: #2BBBAD;
$btn-default-text: $darkbg-text;

$btn-primary: $primary;
$btn-primary-text: $darkbg-text;

$btn-success: $success;
$btn-success-text: $darkbg-text;

$btn-info: $info;
$btn-info-text: $darkbg-text;

$btn-warning: $warning;
$btn-warning-text: $darkbg-text;

$btn-danger: $danger;
$btn-danger-text: $darkbg-text;

$input-unchecked: rgba(137, 137, 137, 0.3);
$input-checked: rgba(15, 157, 88, 0.3);

$radio-default: $lightbg-text;
$radio-primary: $primary;
$radio-success: $success;
$radio-info:    $info;
$radio-warning: $warning;
$radio-danger:  $danger;

$input-danger: $danger;
$input-default: $primary;
$input-warning: $warning;
$input-success: $success;
$input-info:    $info;

$navbar-danger-bg: $danger;
$navbar-inverse-bg: #5264AE;
$navbar-warning-bg: $warning;
$navbar-success-bg: $success;
$navbar-info-bg:    $info;
$navbar-default-bg: $primary;
$navbar-color: $darkbg-text;
$navbar-link-color: $darkbg-text;
$navbar-link-hover-color: $darkbg-text;
$navbar-link-hover-bg: transparent;
$navbar-link-active-color: $darkbg-text;
$navbar-link-active-bg: rgba(0,0,0,0.05);
$navbar-link-disabled-color: #E5E5E5;
$navbar-link-disabled-bg: transparent;
$navbar-brand-color: $darkbg-text;
$navbar-toggle-border-color: $darkbg-text;
$navbar-toggle-hover-bg: transparent;
$navbar-toggle-icon-bar-bg: $darkbg-text;
$navbar-border: $darkbg-text;

$alert-success: $success;
$alert-info: $info;
$alert-warning: $warning;
$alert-danger: $danger;

$progress-success: $success;
$progress-info: $info;
$progress-warning: $warning;
$progress-danger: $danger;

//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     6px !default;
$padding-base-horizontal:   12px !default;

$padding-xlarge-vertical:    18px !default;
$padding-xlarge-horizontal:  24px !default;

$padding-large-vertical:    14px !default;
$padding-large-horizontal:  20px !default;

$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;

$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;

$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;

$border-radius-base:        4px !default;
$border-radius-large:       6px !default;
$border-radius-small:       3px !default;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    #fff !default; 

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base:          4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large:         5px !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          14px !default;
$font-size-xlarge:        ceil(($font-size-base * 1.5)) !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14 
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base !default;
$btn-border-radius-large:        $border-radius-large !default;
$btn-border-radius-small:        $border-radius-small !default;

// MSC
$border-light: 1px solid #e0e0e0;