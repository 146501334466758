#footer-spacer {
  pointer-events: none;
  //these original values are temporarily commented out to shrink the footer contact section and hide the extra space

  @include breakpoint(xs) {
    height: 524px;
    //margin-bottom: 150px;
  }
  @include breakpoint(sm) {
    height: 478px;
    //margin-bottom: 172px;
  }
  @include breakpoint(md) {
    height: 500px;
    //margin-bottom: 150px;
  }
  @include breakpoint(lg) {
    height: 575px;
  }
  @include breakpoint(xlg) {
    height: 575px;
  }
}
#footer-spacer.modal-open {
  pointer-events: none;
  //these original values are temporarily commented out to shrink the footer contact section and hide the extra space

  @include breakpoint(xs) {
    height: 524px;
    //margin-bottom: 150px;
  }
  @include breakpoint(sm) {
    height: 478px;
    //margin-bottom: 172px;
  }
  @include breakpoint(md) {
    height: 150px;
    //margin-bottom: 150px;
  }
  @include breakpoint(lg) {
    height: 150px;
  }
  @include breakpoint(xlg) {
    height: 150px;
  }
}
.footer,
.contact-footer {
  z-index: -1;
  display: inline-block;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s linear 0.3s;

  &.hide-loading {
    opacity: 1;
  }

  .footer-top {
    position: relative;
    background-color: $int-bright-blue-2;
    overflow: hidden;

    @include breakpoint(xs) {
      height: 374px;
    }
    @include breakpoint(sm) {
      height: 306px;
    }
    @include breakpoint(md) {
      height: 350px;
    }
    @include breakpoint(lg) {
      height: 425px;
    }
    @include breakpoint(xlg) {
      height: 425px;
    }

    .shadow-overlay {
      height: 24px;
      top: 0;
      width: 100%;
      left: 0;
      position: absolute;
      background-image: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);
    }

    .footer-top-menu {
      h5 {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
        padding: 30px 15px 0;
        color: $int-light-blue-2;
        text-align: center;

        span {
          display: block;

          @include breakpoint(sm) {
            display: inline;
          }
        }

        @include breakpoint(sm) {
           margin: 50px 0 0;
        }
        @include breakpoint(md) {
          font-size: 24px;
          line-height: 30px;
        }
        @include breakpoint(lg) {
          font-size: 29px;
          margin-top: 63px;
        }
      }

      @include breakpoint(xs) {
        height: 137px;
      }
      @include breakpoint(sm) {
        height: 306px;
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
      @include breakpoint(md) {
        height: 350px;
      }

      .footer-swiper {
        .slick-dots {
          bottom: -36px;

          @include breakpoint(sm) {
            bottom: 264px;
          }

          li {
            margin: 0;
            width: 18px;

            &.slick-active {
              button {
                &:before {
                  color: $int-light-blue;
                  opacity: 1;
                }
              }
            }

            button {
              background: none;
              &:hover:before,
              &:active:before {
                color: $int-light-blue;
              }
              &:before {
                font-size: 12px;
                transition: color .2s linear, opacity .2s linear;
                color: $int-dark-blue-2;
                opacity: 1;

                @include breakpoint(sm) {
                  font-size: 17px;
                }
              }
            }
          }
        }
        .swiper-slide {
          a {
            display: block;
            margin: 0 auto;
            width: 150px;

            &:focus {
              outline: none;
            }

            &:hover, &:focus {
              text-decoration: none;
              p {
                text-decoration: none;
              }
            }
          }
          img {
            width: 150px;
            position: relative;
            top: -27px;
            left: 50%;
            transform: translateX(-50%);
          }

          p {
            font-family: $montserrat-bold;
            font-size: 18px;
            line-height: 14px;
            text-align: center;
            color: $int-white;
            text-transform: uppercase;
            margin-top: -11px;
          }
        }
        @include breakpoint(sm) {
          display: none;
        }
      }

      .footer-top-menu-grid {
        display: none;

        @include breakpoint(sm) {
          display: block;
          margin-top: 15px;
        }
      }

      .centering {
        width: fit-content;
        margin: 0 auto;
      }

      .footer-top-menu-item,
      .contact-footer-top-menu-item {
        display: inline-block;
        height: auto;

        @include breakpoint(sm) {
          width: 80px;
          margin: 0 15px;
          position: relative;
          top: 230px;
        }
        @include breakpoint(md) {
          width: 100px;
          margin: 0 21px;
        }
        @include breakpoint(lg) {
          width: 150px;
          margin: 0 27px;
        }
        @include breakpoint(xlg) {
          margin: 0 60px;
        }

        img {
          transition: transform 0.25s ease-in-out;
          position: relative;
          z-index: 2;
        }

        p {
          font-family: $montserrat-bold;
          font-size: 16px;
          line-height: 14px;
          text-align: center;
          color: $int-white;
          text-transform: uppercase;
          margin-top: -12px;
          opacity: 0;
          transition: opacity 0.25s linear;

          @include breakpoint(md) {
             margin-top: -20px;
          }

          @include breakpoint(lg) {
            margin-top: -17px;
            font-size: 18px;
          }
        }

        &:hover {
          text-decoration: none;
          color: $int-white;
          cursor: pointer;
          img {
            transform: translateY(-25px);
            transition: transform 0.15s linear;

            @include breakpoint(md) {
              transform: translateY(-34px);
            }
            @include breakpoint(md) {
              transform: translateY(-32px);
            }
          }
          p {
            opacity: 1;
            transition: opacity .25s linear;
            color: $int-white;
            text-decoration: none;
          }
        }
      }
    }
  }

  .footer-bottom {
    height: 250px;
    background-color: #141d44;

    @include breakpoint(sm) {
      height: 250px;
    }

    @include breakpoint(md) {
      height: 186px;
    }

    .container-fluid {
      padding-top: 36px;

      @include breakpoint(sm) {
        padding-top: 42px;
      }
      @include breakpoint(md) {
        padding-top: 63px;
      }

      .row {
        max-width: 1200px;
        margin: 0 auto;
      }
    }

    .footer-contact {
      width: 100%;
      padding: 0;

      @include breakpoint(md) {
        width: 75%;
      }
    }

    .footer-bottom-brand {
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      display: block;
      text-align: center;
      font-family: $montserrat-bold;

      &:hover, &:focus {
        text-decoration: none;
        outline: none;
      }

      @include breakpoint(md) {
        margin-left: 20px;
        text-align: left;
        display: inline-block;
      }

      @include breakpoint(xlg) {
        margin-left: 15px;
        font-size: 16px;
      }
    }

    .footer-bottom-divider {
      width: 3px;
      height: 30px;
      margin-right: 10px;
      margin-left: 10px;
      display: none;

      @include breakpoint(md) {
        display:inline;
      }

      @include breakpoint(xlg) {
        margin-right: 20px;
        margin-left: 20px;
      }
    }

    .footer-bottom-address {
      font-size: 12px;
      font-weight: 600;
      color: #0069ab;
      display: block;
      text-align: center;
      padding-top: 4px;
      font-family: $montserrat-bold;

      &:hover, &:focus {
        text-decoration: none;
        outline: none;
      }

      @include breakpoint(sm) {
        padding-top: 9px;
      }
      @include breakpoint(md) {
        text-align: left;
        display: inline-block;
        padding-top: 0;
      }

      @include breakpoint(xlg) {
        font-size: 16px;
      }
    }

    .footer-social {
      text-align: center;
      padding-top: 17px;
      width: 100%;

      @include breakpoint(sm) {
        padding-top: 19px;
      }

      @include breakpoint(md) {
        text-align: right;
        padding-top: 0;
        width: 25%;
      }
    }

    .footer-bottom-icon {
      margin-right: 10px;
      margin-left: 10px;

      > .svg {
        width: 30px;
        height: 30px;

        path.social-2 {
          fill: $int-torquoise;
          transition: fill 0.3s linear;
        }

        &:hover path.social-2 {
          fill: $int-white;
        }
      }
    }
  }
}

.footer-modal-overlay {
  background-color: $int-black;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  z-index: 1001;

  &.active {
    opacity: 0.6;
    pointer-events: auto;
  }
}

.footer-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 290px;
  z-index: 1002;
  display: block;
  background-color: $int-xlight-gray;
  border-radius: 3px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s linear;

  &.active {
    pointer-events: auto;
    opacity: 1;
  }

  @include breakpoint(md) {
    width: 600px;
    border-radius: 6px;
  }

  &#call-modal {
    height: 406px;

    @include breakpoint(md) {
      height: 700px;
    }

    .call-us-image {
      width: 333px;
      position: absolute;
      left: -22px;
      top: -29px;

      @include breakpoint(md) {
        width: 690px;
        left: -45px;
        top: -60px;
      }
    }

    .content {
      text-align: center;
      margin-top: 175px;
      padding: 0 15px;

      @include breakpoint(md) {
        margin-top: 352px;
        padding: 0 40px;
      }

      .cta {
        font-size: 18px;
        line-height: 22px;
        color: $int-dark-gray;
        margin: 0 0 18px;

        @include breakpoint(md) {
          font-size: 30px;
          line-height: 36px;
          margin-bottom: 31px;
        }
      }
      p {
        font-size: 15px;
        line-height: 24px;
        font-family: $montserrat-regular;
        color: $int-light-gray;
        margin: 0 0 27px;

        @include breakpoint(md) {
          font-size: 21px;
          line-height: 25px;
          width: 450px;
          margin: 0 auto 56px;
        }
      }
      .int-button {
        color: $int-white;
        background-color: $int-light-blue-3;
        margin-bottom: 13px;

        @include breakpoint(md) {
          font-size: 21px;
          width: 250px;
          margin-bottom: 35px;
        }
      }
      .close-button {
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        font-family: $montserrat-bold;
        color: $int-light-gray;

        @include breakpoint(md) {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  &#email-modal {
    height: 450px;

    @include breakpoint(md) {
      height: 780px;
    }

    @include breakpoint(xlg) {
      height: 600px;
      width: 1050px;
    }

    .email-us-image {
      height: 113px;
      position: absolute;
      left: -20px;
      top: -32px;

      &.large {
        display: none;

        @include breakpoint(xlg) {
          display: block;
          height: 600px;
          top: 0;
          left: -55px;
          z-index: 3;
        }
      }

      @include breakpoint(md) {
        height: 235px;
        left: -44px;
        top: -66px;
      }

      @include breakpoint(xlg) {
        display: none;
      }
    }

    form {
      padding: 0 20px;
      margin-top: 103px;
      margin-bottom: 50px;

      @include breakpoint(md) {
        padding: 0 40px;
        margin-top: 217px;
      }

      @include breakpoint(xlg) {
        padding: 0 90px;
        width: 631px;
        margin-top: 41px;
        margin-left: 419px;
      }

      h3 {
        color: $int-dark-gray;
        font-size: 24px;
        line-height: 24px;
        margin: 0 0 12px;

        @include breakpoint(md) {
          margin-bottom: 30px;
        }

        @include breakpoint(xlg) {
          font-size: 36px;
          line-height: 50px;;
        }
      }

      .form-group {
        position: relative;
        z-index: 1003;
      }

      input, textarea {
        font-size: 12px;
        line-height: 24px;
        color: $int-dark-gray;
      }

      input {
        border: none;
        border-bottom: 1px solid $int-light-gray;
        margin-bottom: 18px;
        height: auto;
        box-shadow: none;

        &:focus {
          border-bottom-color: $int-light-blue-3;
        }

        @include breakpoint(md) {
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 34px;
        }

        @include breakpoint(xlg) {
          width: 360px;
          margin-bottom: 30px;
        }
      }

      textarea {
        height: 86px;
        padding: 10px 15px;
        margin-top: 20px;
        resize: none;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        @include breakpoint(md) {
          height: 150px;
          padding: 25px 30px;
          font-size: 16px;
          line-height: 20px;
          margin-top: 40px;
        }
        @include breakpoint(md) {
          margin-top: 42px;
        }
      }

      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $int-light-gray;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: $int-light-gray;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: $int-light-gray;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: $int-light-gray;
      }

      .button-background {
        height: 72.5px;
        width: 290px;
        background-color: $int-light-blue-3;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0 0 3px 3px;

        @include breakpoint(md) {
          height: 150px;
          width: 600px;
          border-radius: 0 0 6px 6px;
        }

        @include breakpoint(xlg) {
          height: 150px;
          width: 631px;
          margin-left: 419px;
          border-radius: 0 0 6px 0;
          z-index: 2;
          background-color: $int-medium-blue-5;
        }
        button {
          background-color: $int-light-blue-3;
          color: $int-white;
          border: none;
          font-size: 15px;
          line-height: 50px;
          position: relative;
          top: 17px;

          @include breakpoint(md) {
            font-size: 21px;
            line-height: 70px;
            top: 66px;
          }

          @include breakpoint(xlg) {
            background-color: $int-medium-blue-5;
            font-size: 16px;
            top: 74px;
          }
        }
      }
    }
  }
}

.footer-disclaimer {
    color: grey;
    font-size: 9pt;
    font-family: "Arial", "montserrat-regular";
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @include breakpoint(sm){
      margin-left: 5px;
      text-align: left;
    }
    @include breakpoint(xlg) {
      margin-left: 0;
    }

}