.tracking {
  #map {
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    left: 0;
    top: 0;
    transition: opacity .3s linear;

    &.active {
      opacity: 1;
      z-index: 3;
    }
  }
  .tracking-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: $int-dark-blue-2;
    transition: opacity 0.3s linear 0.3s;
    pointer-events: none;
    z-index: 3;

    &.active {
      transition: opacity 0.3s linear;
      opacity: 0.8;
      pointer-events: auto;
    }
  }
  .col- {
    &sm, &md, &lg {
      &12, &10, &8, &6 {
        z-index: 100;
      }
    }
  }
  &--gradient,
  &--wrapper {
    margin-left: -15px;
    margin-right: -15px;
  }

  &--gradient {
    height: 100vh;
    position: relative;
    z-index: 0;
    background-color: $int-dark-blue-2;
    background-image: none;
  }

  &--bg {
    background-color: #0431AF;
  }

  .row {
    padding-left: 30px;
    padding-right: 30px;

    div:first-child {
      text-align: center;
      @include breakpoint(md) {
        text-align: left;
      }
    }
  }

  .row.tracking--bg.tracking--wrapper {
    height: 100vh;
    padding-left: 0;
    padding-right: 0;
  }

  &--wrapper {
    position: relative;
  }

  h1,
  h2 {
    font-size: 24px;
    line-height: 29px;

    @include breakpoint(sm) {
      font-size: 36px;
      line-height: 48px;
    }
    @include breakpoint(md) {
    }
    @include breakpoint(xlg) {
      font-size: 48px;
      line-height: 48px;
    }
  }

  h1 {
    color: $int-yellow;
    display: block;

    @include breakpoint(sm) {
      display: inline;
      line-height: 40px;
    }

    @include breakpoint(lg) {
      margin-bottom: 7px;
      display: block;
    }

    @include breakpoint(xlg) {
      font-size: 36px;
    }
  }

  h2 {
    color: $int-medium-blue;
    font-size: 21px;
    line-height: 25px;
    margin-top: 17px;

    @include breakpoint(sm) {
      font-size: 30px;
      line-height: 36px;
      max-width: 357px;
      margin: 14px auto 0;
    }

    @include breakpoint(lg) {
      margin: 0 auto 0 0;
      max-width: 339px;
    }
  }

  p {
    color: $int-white;
    font-size: 14px;
    line-height: 17px;
    @include breakpoint(sm) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  a {
    text-align: center;
    font-size: (18/16) + em;
  }

  a.int-button,
  h1,
  h2,
  p {
    position: relative;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    max-width: 260px;

    @include breakpoint(sm) {
      max-width: 485px;
    }
  }

  a.int-button,
  p {
    margin-top: 28px;
  }

  a.int-button {
    color: $int-bright-blue-3;
    background: $int-bright-blue-2;
    font-family: $montserrat-bold;
    font-size: 18px;
    transition: color .25s linear;

    &:hover,
    &:active {
      text-decoration: none;
      color: $int-white;
    }

    @include breakpoint(sm) {
      margin-top: 34px;
    }

    @include breakpoint(lg) {
      margin-left: 0;
      height: 60px;
      line-height: 60px;
    }
  }

  &--divider {
    width: 100%;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    margin: 0 auto;

    &__img {
      bottom: 0;
      position: relative;
      display: block;
      height: 100vh;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.tracking--hero {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  margin-top: 15vh;
  opacity: 1;
  transition: opacity 0.3s linear 0.6s;

  &.hide {
    opacity: 0;
    transition: opacity 0.3s linear 0.3s;
  }

  @include breakpoint(lg) {
    text-align: left;
    width: 357px;
    margin-left: 95px;
    margin-top: 20vh;
  }

  @include breakpoint(xlg) {
    margin-left: 8vw;
  }

  p {
    display: none;

    @include breakpoint(sm) {
      display: block;
      max-width: 405px;
      margin: 22px auto 10px;
    }

    @include breakpoint(lg) {
      margin: 37px auto 10px 0;
      max-width: 335px;
    }

    @include breakpoint(xlg) {
      font-size: 16px;
      line-height: 24px;
      max-width: 359px;
    }
  }
}

.tracking--track {
  margin: 0 auto;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s linear 0s;
  width: 280px;

  @include breakpoint(sm) {
    width: 350px;
  }

  &.active {
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.3s linear 0.5s;
  }

  .hero-track-text {
    text-align: center !important;
    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
    h2 {
      margin-bottom: 30px;

      @include breakpoint(lg) {
        margin: 14px auto 30px;
      }
    }
  }

  form {
    text-align: center;
    position: relative;
  }

  p {
    color: $int-light-blue;
    opacity: 0;
    transition: opacity 0.3s linear;
    text-align: center;

    &.show {
      opacity: 1
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 14px;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    font-size: 14px;
  }
  :-ms-input-placeholder { /* IE 10+ */
    font-size: 14px;
  }
  :-moz-placeholder { /* Firefox 18- */
    font-size: 14px;
  }

  input[type="text"] {
    width: 225px;
    height: 60px;
    border-radius: 5px;
    border: 0px;
    padding-left: 15px;
    padding-right: 40px;
    background-color: $int-white;
    color: $int-medium-gray;
    font-family: $montserrat-bold;

    &:focus, &:active {
      outline: none;
      box-shadow: none;
      border-bottom: none;
    }

    @include breakpoint(sm) {
      width: 275px;
      padding-left: 25px;
      padding-right: 50px;
    }
  }

  button.button-icon {
    background: $int-light-gray;
    background-image: url(/images/track-cheveron.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 9px 13px;
    border: solid 1px $int-light-gray;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    position: absolute;
    right: 5px;
    top: 15px;

    @include breakpoint(sm) {
      right: 25px;
    }
  }
}

.tracking-response {
  background-color: $int-dark-blue-2;
  position: absolute;
  top: 0;
  left: -150vw;
  z-index: 1001;
  transition: left 0.5s linear;
  height: 100vh;

  @include breakpoint(md) {
    width: 450px;
    padding: 0;
    left: -500px;
    //min-height: 100vh;
  }

  &.open {
    left: 0;
  }

  .overflow-container {
    height: 100vh;
    overflow-y: scroll;
  }

  .tracking-response-header {
    display: none;

    @include breakpoint(md) {
      display: block;
      background-color: $int-dark-blue-2;
      width: 100%;
    }

    h3, h4 {
      width: 370px;
      text-align: center;
      margin: 0 auto;

      @include breakpoint(xlg) {
        widtH: 276px;
      }
    }

    h3 {
      color: $int-yellow;
      font-size: 36px;
      line-height: 43px;
      padding-top: 70px;

      @include breakpoint(xlg) {
        padding-top: 81px;
      }
    }
    h4 {
      color: $int-medium-blue;
      font-size: 30px;
      line-height: 36px;
      padding: 8px 0 43px;

      @include breakpoint(xlg) {
        font-size: 36px;
        line-height: 43px;
        padding-top: 11px;
      }
    }
  }

  .tracking-response-body {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    border-radius: 6px 6px 0 0;
    width: 290px;
    margin: 0 auto;
    background-color: $int-white;
    padding-bottom: 31px;
    min-height: 100vh;

    @include breakpoint(sm) {
      width: 484px;
    }

    @include breakpoint(md) {
      width: 100%;
      min-height: calc(100vh - 200px);
    }

    .tracking-response-title-mobile {
      padding: 27px 15px 23px;

      @include breakpoint(sm) {
        padding: 56px 15px 41px;
      }

      @include breakpoint(md) {
        display: none;
      }

      h5 {
        color: $int-white;
        font-size: 21px;
        line-height: 25px;
        margin: 0 auto;
        text-align: center;

        @include breakpoint(sm) {
          font-size: 36px;
          line-height: 43px;
        }
      }
    }

    .tracking-number {
      background-color: $int-medium-blue;
      padding: 17.5px 15px;

      @include breakpoint(md) {
        padding: 17px 15px;
      }

      .tracking-status {
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        .error-icon, .success-icon {
          height: 25px;
          width: auto;
        }
        p {
          color: $int-white;
          margin: 0;
          display: inline;
          font-family: $montserrat-bold;
          font-size: 18px;
          line-height: 22px;
          vertical-align: middle;
          padding-left: 10px;

          @include breakpoint(sm) {
            font-size: 24px;
            line-height: 26px;
          }
        }
      }
    }
    .tracking-text {
      background-color: $int-white;
      padding: 36px 15px 13px;

      @include breakpoint(sm) {
        padding: 63px 15px 40px;
      }

      @include breakpoint(md) {
        padding: 49px 15px 59px;
      }

      @include breakpoint(xlg) {
        padding: 66px 15px 61px;
      }

      h5 {
        display: none;

        @include breakpoint(md) {
          display: block;
          font-size: 30px;
          line-height: 36px;
          text-align: center;
          width: 328px;
          margin: 0 auto 24px;
          color: $int-bright-red;
        }

        @include breakpoint(xlg) {
          margin-bottom: 53px;
        }
      }
      p {
        color: $int-light-gray;
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        margin: 0 0 10px;

        @include breakpoint(sm) {
          font-size: 21px;
          line-height: 30px;
          width: 357px;
          margin: 0 auto 15px;
        }

        @include breakpoint(md) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    .tracking-help {
      .help-menu {
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        .help-menu-item {
          height: inherit;
          width: inherit;
          font-size: 0;
          margin: 0 7.5px;

          @include breakpoint(sm) {
            margin: 0 12.5px;
          }

          img {
            height: 60px;
            width: 60px;

            @include breakpoint(sm) {
              height: 80px;
              width: 80px;
            }

            @include breakpoint(xlg) {
              height: 100px;
              width: 100px;
            }
          }

          &:hover, &:focus, &:active {
            text-decoration: none;
            outline: none;
          }
        }
      }

      .help-track-another {
        display: block;
        text-align: center;
        margin: 28px auto 32px;
        text-transform: uppercase;
        color: $int-light-gray;
        background-color: $int-xlight-gray;
        font-size: 14px;
        line-height: 50px;
        font-family: $montserrat-bold;
        width: 217px;
        border-radius: 3px;
        transition: color 0.3s linear;

        @include breakpoint(sm) {
          font-size: 16px;
          line-height: 60px;
          width: 290px;
          margin: 75px auto 76px;
        }

        @include breakpoint(md) {
          margin: 91px auto 78px;
        }

        @include breakpoint(lg) {
          margin: 42px auto 13px;
        }

        @include breakpoint(xlg) {
          margin: 69px auto 39px;
        }

        &:hover, &:focus, &:active {
          color: $int-dark-blue;
          text-decoration: none;
          outline: none;
        }
      }
    }
  }

  &.tracking-response-error {
    .tracking-response-body {
      .tracking-response-title-mobile {
        background-color: $int-bright-red;
      }
    }
  }

  &.tracking-response-success {
    .close-success {
      background-color: $int-black;
      position: absolute;
      right: -30px;
      height: 30px;
      width: 30px;

      @include breakpoint(sm) {
        right: -44px;
        height: 44px;
        width: 44px;
      }

      @include breakpoint(xlg) {
        height: 50px;
        width: 50px;
        right: -50px;
      }

      img {
        height: 16px;
        width: auto;
        margin: 7px 10px;
        //transform: rotate(0deg);
        //transition: transform 0.3s linear;

        @include breakpoint(sm) {
          height: 20px;
          margin: 12px 15px;
        }

        @include breakpoint(xlg) {
          margin: 15px 20px;
        }
      }
    }

    //background-image: url(/images/success-close.svg);
    //background-repeat: no-repeat;
    //background-size: auto 16px;
    //background-position: 10px 7px;
    //
    //@include breakpoint(sm) {
    //  right: -44px;
    //  height: 44px;
    //  width: 44px;
    //  background-position: 15px 12px;
    //  background-size: auto 20px;
    //}
    //
    //@include breakpoint(xlg) {
    //  height: 50px;
    //  width: 50px;
    //  background-position: 20px 15px;
    //}

    &.open {
      transition: left 0.3s linear;
      .close-success {
        img {
          transform: rotateY(0deg);
          transition: transform 0.5s linear;
        }
      }
      &.closed {
        left: -290px;

        @include breakpoint(sm) {
          left: -484px;
        }

        @include breakpoint(md) {
          left: -450px;
        }

        .close-success {
          img {
            transform: rotateY(180deg);
          }
        }
      }
    }

    .tracking-response-body {
      .tracking-response-title-mobile {
        background-color: $int-bright-green-2;
      }

      .tracking-text {
        padding: 8px 15px 0;
        border-bottom: 1px solid $int-xlight-gray;

        @include breakpoint(sm) {
          padding-top: 17px;
        }
        @include breakpoint(md) {
          padding-top: 40px;
        }
        @include breakpoint(xlg) {
          padding-top: 46px;
        }
        @include breakpoint(xxlg) {
          padding-top: 45px;
        }

        h5 {
          color: $int-bright-green-2;

          @include breakpoint(md) {
            margin-bottom: 21px;
          }
        }
        h6 {
          font-size: 18px;
          line-height: 22px;
          color: $int-light-gray;
          text-align: center;
          font-family: $montserrat-bold;
          margin: 10px 0;

          @include breakpoint(md) {
            margin-bottom: 5px;
          }

          @include breakpoint(xxlg) {
            margin-bottom: 6px;
          }
        }

        p {
          font-size: 17px;
          line-height: 20px;
          margin: 0 auto;

          &:last-of-type {
            padding-bottom: 14px;

            @include breakpoint(sm) {
              padding-bottom: 20px;
            }
            @include breakpoint(md) {
              padding-bottom: 47px;
            }
            @include breakpoint(xlg) {
              padding-bottom: 42px;
            }
            @include breakpoint(xxlg) {
              padding-bottom: 41px;
            }
          }
        }
      }
      .tracking-locations {
        margin: 0 0 40px;
        //height: 391px;
        //overflow-x: hidden;
        //overflow-y: auto;

        @include breakpoint(sm) {
          //height: 472px;
          margin-bottom: 45px;
        }
        @include breakpoint(md) {
          //height: 448px;
          margin-bottom: 87px;
        }
        @include breakpoint(lg) {
          margin-bottom: 38px;
        }
        @include breakpoint(xlg) {
          margin-bottom: 45px;
        }
        @include breakpoint(xxlg) {
          margin-bottom: 64px;
        }

        .location {
          padding: 0 15px 0 30px;

          @include breakpoint(sm) {
            padding: 0 95px;
          }

          &:not(:first-of-type) {
            border-top: 1px solid $int-xlight-gray;
          }

          img {
            width: 15px;
            height: auto;
            position: relative;
            top: -26px;
            margin-right: 34px;
            margin-left: 8px;

            @include breakpoint(sm) {
              top: -42px;
            }
          }

          .content {
            width: 163px;
            margin: 35px 0 33px auto;
            display: inline-block;

            h6 {
              color: $int-medium-gray;
              font-size: 14px;
              line-height: 17px;
              margin: 0 0 3px;
              text-transform: uppercase;
              font-family: $montserrat-bold;

              @include breakpoint(sm) {
                margin-bottom: 7px;
              }
            }

            p {
              color: $int-light-gray;
              font-size: 12px;
              line-height: 14px;
              margin: 0;
              font-family: $montserrat-bold;

              @include breakpoint(sm) {
                line-height: 19px;
              }

              &.place {
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
}



