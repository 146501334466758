/*********************
  Navbar
**********************/

.navbar {
    border: 0;
    border-radius: 0;
    font-weight: 300;

    .navbar-brand {
        position: relative;
        height: 60px;
        line-height: 30px;
        color: $navbar-brand-color;
        &:hover,
        &:focus {
            color: $navbar-brand-color;
            background-color: transparent;
        }
    }
    .navbar-text {
        color: $navbar-color;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .navbar-nav {
        > li > a {
            color: $navbar-link-color;
            padding-top: 20px;
            padding-bottom: 20px;
            &:hover,
            &:focus {
                color: $navbar-link-hover-color;
                background-color: $navbar-link-hover-bg;
            }
        }
        > .active > a {
            &,
            &:hover,
            &:focus {
                color: $navbar-link-active-color;
                background-color: $navbar-link-active-bg;
            }
        }
        > .disabled > a {
            &,
            &:hover,
            &:focus {
                color: $navbar-link-disabled-color;
                background-color: $navbar-link-disabled-bg;
            }
        }
    }

    // Darken the responsive nav toggle
    .navbar-toggle {
        border-color: $navbar-toggle-border-color;
        &:hover,
        &:focus {
            background-color: $navbar-toggle-hover-bg;
        }
        .icon-bar {
            background-color: $navbar-toggle-icon-bar-bg;
        }
    }

    .navbar-collapse,
    .navbar-form {
        border-color: rgba(0,0,0,0.1);
    }

    // Dropdowns
    .navbar-nav {
        > .open > a {
            &,
            &:hover,
            &:focus {
                background-color: $navbar-link-active-bg;
                color: $navbar-link-active-color;
            }
        }

        @media (max-width: 767px) {
            // Dropdowns get custom display
            .open .dropdown-menu {
                > .dropdown-header {
                    border: 0;
                    color: darken($navbar-link-color, 17%)
                }
                .divider {
                    background-color: $navbar-border;
                }
                > li > a {
                    color: $navbar-link-color;
                    &:hover,
                    &:focus {
                        color: $navbar-link-hover-color;
                        background-color: $navbar-link-hover-bg;
                    }
                }
                > .active > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $navbar-link-active-color;
                        background-color: $navbar-link-active-bg;
                    }
                }
                > .disabled > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $navbar-link-disabled-color;
                        background-color: $navbar-link-disabled-bg;
                    }
                }
            }
        }
    }

    .navbar-link {
        color: $navbar-link-color;
        &:hover {
            color: $navbar-link-hover-color;
        }
    }

    .navbar-link {
        color: $navbar-link-color;
        &:hover,
        &:focus {
            color: $navbar-link-hover-color;
        }
        &[disabled],
        fieldset[disabled] & {
            &:hover,
            &:focus {
                color: $navbar-link-disabled-color;
            }
        }
    }

    .navbar-form {
        margin-top: 0;
        margin-bottom: 0;
        .form-control-wrapper .form-control, .form-control {
            border-color: $navbar-border;
            color: $navbar-border;
        }
        .form-control-wrapper {
            .material-input:before, input:focus ~ .material-input:after {
                background-color: $navbar-border;
            }
        }
        ::-webkit-input-placeholder { color: $navbar-border; }
        :-moz-placeholder { color: $navbar-border; };
        ::-moz-placeholder { color: $navbar-border; };
        :-ms-input-placeholder { color: $navbar-border; };
    }
}

.navbar-inverse {
        background-color: $navbar-inverse-bg;
    }

.navbar-white {
        background-color: #FFF;
        .navbar-brand, .navbar-brand:hover, .navbar-brand:focus {
            color: $lightbg-text;
        }
        .navbar-nav {
            & > li > a {
                color: $lightbg-text;

                &:hover,
                &:focus {
                    color: $lightbg-text;
                    background-color: $navbar-link-hover-bg;
                }
            }
            & > .active > a {
                &,
                &:hover,
                &:focus {
                    color: $lightbg-text;
                    background-color: $navbar-link-active-bg;
                }
            }
            & > .disabled > a {
                &,
                &:hover,
                &:focus {
                    color: $lightbg-text;
                    background-color: $navbar-link-disabled-bg;
                }
            }

            & > .open > a {
                &,
                &:hover,
                &:focus {
                    background-color: $navbar-link-active-bg;
                    color: $lightbg-text;
                }
            }

        }
    }
//Fixed Navbar

@media(min-width:767px) {
    .navbar-fixed-top {
        padding: 10px 0;
        background-color: transparent;
        -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
        -moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
        transition: background .5s ease-in-out, padding .5s ease-in-out;
        z-index: 10;
        .navbar-nav > li {
            color: #666666;
            font-size: 20px;
            transition-duration: 1s;
        }
    }
    .top-nav-collapse {
        padding: 0;
        background-color: inherit;
        .navbar-nav > li > a {
            color: #fff;
            transition-duration: 1s;
            font-size: 20px;
        }
        
    }
}
