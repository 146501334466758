@font-face {
  font-family: 'montserrat-bold';
  src: url('/fonts/montserrat-bold-webfont.woff2') format('woff2'),
  url('/fonts/montserrat-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-regular';
  src: url('/fonts/montserrat-regular-webfont.woff2') format('woff2'),
  url('/fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-regular';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'),
  url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-medium';
  src: url('/fonts/Roboto-Medium.woff2') format('woff2'),
  url('/fonts/Roboto-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$montserrat-bold: 'montserrat-bold';
$montserrat-regular: 'montserrat-regular';
$roboto-regular: 'roboto-regular';
$roboto-medium: 'roboto-medium';