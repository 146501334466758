.online-tools {
    > .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    .page-wrapper {
        overflow-x: hidden;
    }

    .onlineTools {
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
        //height: 100vh;
        background-image: linear-gradient(to bottom, #0477F8 0%, $int-dark-blue-2 75vh);
        height: auto;
        min-height: 100vh;


        .row {
            max-width: 1200px;
            margin: auto auto;
        }

        &--hero {
            text-align: center;
            margin-top: 13.75vh;
            position:relative;
            z-index: 2;
            margin-bottom: 50px;

            @include breakpoint(sm){
                margin-top: 16.5vh;
            }
            @include breakpoint(md){
                margin-top: 19vh;
                margin-bottom: 120px;
            }
            @include breakpoint(lg){
                margin-top: 25vh;
                margin-bottom: 65px;
            }

            h1 {
              color: $int-yellow;
              font-size: 24px;
              line-height: 33px;
              //line-height: normal;
              margin: 0 ;
              color: $int-tool-hover-yellow;
                letter-spacing: -.87px;

              @include breakpoint(sm){
                  font-size: 30px;
                  line-height: 37px;
                  letter-spacing: -1.09px;
              }

              @include breakpoint(md){
                  font-size: 36px;
                  line-height: 44px;
                  letter-spacing: -1.31px;
              }

              @include breakpoint(lg){
                  font-size: 48px;
                  line-height: 48px;
                  letter-spacing: -1.75px;
              }
                @include breakpoint(xlg){
                    line-height: 56px;
                }
            }

            h2 {
              color: $int-light-blue;
              font-size: 24px;
              line-height: 29px;
              //line-height: normal;
              margin: 0;
                letter-spacing: -.87px;

              @include breakpoint(sm){
                  font-size: 30px;
                  line-height: 36px;
                  letter-spacing: -1.09px;
              }

              @include breakpoint(md){
                  font-size: 36px;
                  line-height: 42px;
                  letter-spacing: -1.13px;
              }

              @include breakpoint(lg){
                  font-size: 48px;
                  line-height: 48px;
                  letter-spacing: -1.75px;
              }
            }

            //p.hidden-until-sm {
            //    color: $int-white;
            //    font-size: 16px;
            //    line-height: 24px;
            //    display: none;
            //    font-family: $montserrat-bold;
            //
            //    @include breakpoint(sm){
            //        display: block;
            //        width: 377px;
            //        margin: 15px auto 35px;
            //    }
            //
            //    @include breakpoint(lg) {
            //        width: 482px;
            //        margin-bottom: 46px;
            //        br {
            //            display: none;
            //        }
            //    }
            //
            //    @include breakpoint(xlg) {
            //        width: 558px;
            //        font-size: 18px;
            //        line-height: 30px;
            //        margin-top: 25px;
            //    }
            //}
        }

        .online-tools-swiper {
            position: relative;
            z-index: 3;
            width: 100%;
            max-width: 1000px;
            margin: 0 auto;
            text-align: center;
            max-width: 256px;
            max-width: 260px;
            padding-bottom: 150px;

            @include breakpoint(md){
                max-width: 655px;
                padding-bottom: 200px;
            }

            @include breakpoint(lg){
                max-width: 1000px;
            }

            .tool {
                background: $int-white;
                border: solid 1px $int-white;
                border-radius: 10px;
                text-align: center;
                position: relative;
                top: 0;
                transition: background .25s linear, border .25s linear;
                padding: 11px 7px 44px;
                width: 320px;
                margin: 0 0 102px;
                display: inline-block;
                min-height: 228px;
                min-height: 182px;
                width: 256px !important;
                border: none;

                @include breakpoint(md){
                    margin: 0 5px 87px;
                }
                .content {
                    padding: 0px 20px;
                }

                &:hover {
                    background: $int-dark-blue-3;
                    border-color: $int-dark-blue-3;
                    h3 {
                        color: $int-tool-hover-yellow;
                    }
                    p {
                        color: $int-tool-hover-yellow;
                    }
                }
                &:focus {
                    outline: none;
                }

                //@include breakpoint(lg){
                //    width: 128px;
                //    display: inline-block;
                //    margin: 0 5px;
                //}

                h3 {
                    color: $int-medium-blue-6;
                    font-size: 18px;
                    //line-height: 25px;
                    margin: 10px auto 10px;
                    text-transform: uppercase;
                    max-width: 286px;
                    transition: color .25s linear;
                    line-height: normal;

                }
                p {
                    font-family: $montserrat-regular;
                    font-size: 14px;
                    line-height: 19px;
                    color: $int-medium-blue-6;
                    //text-transform: uppercase;
                    display: inline;
                    //font-weight: 600;
                    //max-width: 242px;
                    margin: 0 auto;
                    transition: color .25s linear;
                    width: 194px;
                    letter-spacing: -.5px;
                    font-weight: 500;
                }
                img {
                    width: 104px;
                    height: 104px;
                    position: absolute;
                    bottom: -52px;
                    left: 50%;
                    transform: translateX(-50%);
                    //border-radius: 50%;
                    &.circle {
                        border-radius: 50%;
                    }
                }
               
            }

          .slick-dots {
            li {
              margin: 0 8px;
              &.slick-active {
                button {
                  &:before {
                    color: $int-tool-hover-yellow;
                  }
                }
              }
              button{
                &:before {
                  font-size: 14px;
                  color: $int-bright-blue-6;
                  opacity: 1;
                }
              }
            }
          }
        }

        &--divider {
            height: 140px;
            background: #0431AF;
            overflow-y: visible;
            overflow-x: hidden;
            z-index: -1;

            &__img {
                position: absolute;
                z-index: 1;
                left: -507%;
                right: -500%;
                top: auto;
                bottom: 15px;
                margin: auto;
                height: 59%;

                @include breakpoint(lg){
                    width: 157.12vw;
                    height: auto;
                }

                @include breakpoint(lg){
                    width: 1912.12px;
                }
            }
        }
    }
    .onlineTools.modal-open {
        max-height: 678px;
    }
}

.calculator-modal-overlay {
    //background-color: $int-bright-blue-2;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    transition: opacity 0.3s linear;
    z-index: 1002;
    background-color: rgba(5, 29, 113, .85);

    &.active {
        opacity: 1;
        pointer-events: auto;
    }
}
