.calculator-modal {
  position: fixed;
  z-index: 1020;
  background: $int-medium-blue-2;
  color: white;
  font-size: 12px;
  border-radius: 5px;
  display: block;
  left: 50%;
  opacity: 0;
  transition: opacity .25s linear;
  pointer-events: none;

  height: auto;
  background: $int-bright-blue-7;
  text-align: center;
  max-width: 440px;
  width: 100%;
  top: 50px;
  transform: translateX(-50%);
  position: absolute;

  @media only screen and (min-height: 969px ) {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @include breakpoint(sm) {
    width: 80.88%;
    max-width: 570px;
  }
  @include breakpoint(md) {
    width: 74.22%;
    max-width: 800px;
  }

  @include breakpoint(lg) {
    width: 80.65%;
    max-width: 900px;
  }

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  .close-button {
    position: absolute;
    right: 19px;
    top: 19px;
    color: black;
    transition: all .3s linear;
    z-index: 1001;

    .svg {
      height: 24px;
      width: auto;
    }

    svg {
      transition: all .3s linear;

      circle {
        fill: $int-medium-blue;
        transition: fill .3s linear;
      }
      polygon {
        fill: $int-medium-blue-2;
        transition: fill .3s linear;
      }
    }

    &:hover {
      svg {
        circle {
          fill: $int-bright-blue-3;
        }
        polygon {
          fill: $int-medium-blue-2;
        }
      }
    }
  }

  .calculator-swiper {
    padding: 107px 0;
    padding: 75px 0 61px;

    .heading {
      max-width: 267px;
      margin: 0 auto 30px;

      @include breakpoint(lg){
        max-width: 525px;
      }

      h1 {
        margin: 0;
        font-size: 27px;
        line-height: 30px;
        color: $int-tool-hover-yellow;
        margin-bottom: 10px;
        letter-spacing: -1.31px;

        @include breakpoint(md){
          font-size: 31px;
          line-height: 34px;
        }
        @include breakpoint(lg){
          font-size: 36px;
          line-height: normal;
        }
      }

      h2 {
        margin: 0;
        font-size: 27px;
        line-height: 30px;
        color: $int-light-blue-5;
        letter-spacing: -1.31px;

        @include breakpoint(md){
          font-size: 31px;
          line-height: 34px;
        }

        @include breakpoint(lg){
          font-size: 36px;
          line-height: normal;
        }
      }
    }

    .swiper-grid-item {
      padding: 7px 6px 21px;

      form {
        padding: 28px 15px 15px;
        width: 356px;
        border-radius: 10px;
        background-color: $int-white-smoke;
        position: relative;
        margin-bottom: 0;

        .title-wrapper {
          position: relative;
          text-align: left;

          h4 {
            color: $int-bright-blue-5;
            font-family: $montserrat-regular;
            font-weight: 600;
            font-size: 18px;
            line-height: normal;
            margin: 0 0 13px 0;
            display: inline-block;
          }
        }
        .form-group {
          label {
            font-family: $montserrat-regular;
            font-weight: 600;
            color: $int-bright-blue-5;
            font-size: 12px;
            color: $int-medium-gray-2;
          }
          input, select {
            font-family: $montserrat-regular;
            border: 2px solid $int-medium-gray-2;
            background-color: $int-white-smoke;
            color: $int-bright-blue-5;
            font-size: 12px;
            font-weight: 600;
            width: 100%;
            margin-bottom: 0;
            padding: 0 6px;
            transition: color .25s linear, border .25s linear;
            height: 42px;
            border-radius: 4px;
            box-shadow: none;
            box-sizing: border-box;

            &:focus, &:active {
              box-shadow: none;
              border-color: $int-bright-blue-5;
              color: $int-bright-blue-5;
            }

            &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
              color: $int-medium-gray-2;
              transition: color .25s linear;
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
              color: $int-medium-gray-2;
              opacity: 1;
              transition: color .25s linear;
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
              color: $int-medium-gray-2;
              opacity: 1;
              transition: color .25s linear;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: $int-medium-gray-2;
              transition: color .25s linear;
            }
            &::-ms-input-placeholder { /* Microsoft Edge */
              color: $int-medium-gray-2;
              transition: color .25s linear;
            }
          }
          input {
            &:focus, &:active {
              &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                color: $int-bright-blue-5;
              }
              &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: $int-bright-blue-5;
                opacity: 1;
              }
              &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color: $int-bright-blue-5;
                opacity: 1;
              }
              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $int-bright-blue-5;
              }
              &::-ms-input-placeholder { /* Microsoft Edge */
                color: $int-bright-blue-5;
              }
            }
          }

          input[type=number]::-webkit-inner-spin-button,
          input[type=number]::-webkit-outer-spin-button {
            display: none;
            margin: 0;
          }

          .selectize-control.single .selectize-input {
            &.dropdown-active:after {
              background: url('../images/blue-up-chevron.svg');
            }
            &:after {
              background: url('../images/grey-down-chevron.svg');
              border: none;
              background-size: 15px 9px;
              height: 9px;
              width: 15px;
            }
          }

          .selectize-input {
            padding: 0 6px;
            text-align: left;
            border: 2px solid $int-medium-gray-2;
            background-color: $int-white-smoke !important;
            font-family: $montserrat-regular;
            color: $int-medium-gray-2;
            font-size: 12px;
            font-weight: 600;
            display: flex;
            align-items: center;
            &.full {
              height: 42px;
              line-height: 42px;
              text-align: left;
            }

            &:focus, &:active, &:focus-within {
              border-color: $int-bright-blue-5;
              color: $int-bright-blue-5;
            }
          }
          .selectize-dropdown {
            margin: -2px 0 0 0;
            text-align: left;
            font-family: $montserrat-regular;
            font-weight: 600;
            line-height: normal;
            border: none;
            background: transparent;

            .selectize-dropdown-content {
              border: 2px solid $int-bright-blue-5;
              border-radius: 4px;
              background-color: $int-white-smoke;
            }

            .option {
              padding: 8px 12px;
              color: $int-medium-gray-2;
              font-size: 12px;
              font-family: $montserrat-regular;
              font-weight: 600;
              line-height: normal;

              &.active {
                background-color: $int-white-smoke;
                color: $int-bright-blue-5;
              }
            }
          }
        }
      }
      button {
        background-color: $int-bright-blue-5;
        transition: background-color .25s linear;
        color: $int-white;
        font-family: $montserrat-bold;
        font-size: 12px;
        display: block;
        width: 100%;
        margin: 0;
        box-shadow: none;
        height: 44px;
        line-height: normal;
        border-radius: 4px;

        &:hover {
          background-color: $int-bright-blue-5;
          color: $int-white;
        }

        &:disabled, &[disabled] {
          background-color: $int-medium-gray-2;
          color: $int-white;
          font-family: $montserrat-bold;
          font-size: 12px;
          display: block;
          width: 100%;
          margin: 0;
          box-shadow: none;
          height: 44px;
          line-height: normal;
          border-radius: 4px;
          opacity: 1;
        }
      }
    }

    .cubic-feet-calculator-slide, .density-calculator-slide, .linear-calculator-slide {
      &.swiper-grid-item {
        form {
          .row {
            .form-group {
              max-width: 100%;
              &:last-of-type {
                margin-right: 0;
              }
              &:nth-of-type(2), &:nth-of-type(3) {
                .selectize-dropdown {
                  left: unset !important;
                  right: 0;
                }
              }
              input {
                margin-bottom: 0;
                width: 100%;
              }
            }

            &.weight-group, .pallet-group, .stack-group {
              margin: 0;
              .form-group {
                vertical-align: bottom;
                width: 100%;

                input {
                  margin-bottom: 0;
                  width: 100%;
                }

                .selectize-dropdown {
                  width: 100% !important;
                }
              }
            }
          }
          .form-group {
            text-align: left;
            margin-right: 10px;
            vertical-align: bottom;
            width: 100%;

            &.restrictHeight {
              height: 64px;
            }

            &.total-group {
              width: 100%;
              display: block;
              input.total {
                margin: 0;
                text-align: center;
                margin-bottom: 15px;
              }
            }

            .selectize-dropdown {
              width: 175px !important;
            }
          }
          @include breakpoint(md){
            & .density-group.form-group, .cubic-group.form-group {
              width: 100%;
              margin-right: 0 !important;
            }
            .form-group {
              text-align: left;
              vertical-align: top;
              width: 136px;
              &.number-of-pallets {
                width: 100%;
              }
              &.restrictHeight {
                height: 64px;
              }

              &.total-group {
                width: 100%;
                display: block;
                input.total {
                  margin: 0;
                  text-align: center;
                  margin-bottom: 15px;
                }
              }

              .selectize-dropdown {
                width: 175px !important;
              }
            }

            .row {
              margin: 0;
              .col-sm-4, .col-sm-8 {
                padding: 0;

                &:first-of-type {
                  .form-group {
                    padding-left: 0;

                    input {
                      padding-right: 9px;
                    }
                  }
                }

                  &:last-of-type {
                    margin-right: 0;
                    .form-group {
                      margin-right: 0;
                      padding-right: 0;

                      input {
                        padding-right: 9px;
                      }
                    }
                  }
                  &:nth-of-type(2), &:nth-of-type(3) {
                    .selectize-dropdown {
                      left: unset !important;
                      right: 0;
                    }
                  }
              }
              .form-group {

                input {
                  margin-bottom: 6px;
                  width: 100%;
                }
              }

              &.weight-group {
                .form-group {
                  width: 100%;
                  vertical-align: bottom;

                  &:last-of-type {
                    margin-right: 0;
                  }

                  input {
                    width: 100%;
                    margin-bottom: 0;
                  }

                  .selectize-dropdown {
                    width: 100% !important;
                  }
                }
              }

            }
          }
          & .stack-group {
            &.form-group {
              margin-right: 0;
              margin-left: 0;
            }
            margin-bottom: 9px;
            text-align: center;
            width: 100%;
            .btn-group label.btn.stack-button {
            border-style: solid;
            border-radius: 4px;
            border-width: 2px;
            margin-right: 25px;
            padding: 5px 10px;
            }
            .btn-group label.btn.stack-button:last-child {
              margin-right: 0px;
            }
            .btn-group label.btn.stack-button{
              box-shadow: none;
            }
            .btn-group label.btn.stack-button.active {
              background-color: #0277f8;
              border-color: #0277f8;
              color: white;
            }
            & .btn-group.linear-stacks-group {
              display: flex;
              justify-content: space-between;
              & label.linear-stack {
                width: 100%;
                margin-bottom: 7px;
              }
              & label.linear-stack:not(:last-child) {
                margin-right: 15px;
              }
            }
          }
        }

      }
    }

    .currency-converter-slide, .weight-converter-slide, .measurement-converter-slide, .density-a-calculator-slide {
      &.swiper-grid-item {
        form {
          .form-group {
            text-align: left;
            width: 100%;

            input {
              width: 100%;
            }

            &.total-group {
              width: 100%;
              display: block;
              input.total {
                margin: 0;
                text-align: center;
                margin-bottom: 15px;
              }
            }
          }

          @include breakpoint(md) {
            width: 356px;

            .row {
              margin: 0;

              .col-sm-6 {
                padding: 0;
              }
            }

            .form-group {
              width: 100%;
              text-align: left;
              vertical-align: top;
              &:last-of-type {
                margin-bottom: 15px;
              }

              .measurement-converter-dropdown, .currency-dropdown, .weight-converter-dropdown {
                height: 64px;
              }
            }
          }
        }
      }
    }

    .slick-arrow {
      display: none;

      &.slick-prev {
        background: url('../images/left-chevron.svg') no-repeat;
        background-size: contain;
        height: 45px;
        width: 30px;
        left: -56px;
        transition: opacity .25s linear;
        &:before {
          content:'';
        }
      }
      &.slick-next {
        background: url('../images/right-chevron.svg') no-repeat;
        background-size: contain;
        height: 45px;
        width: 30px;
        right: -60px;
        transition: opacity .25s linear;
        &:before {
          content: '';
        }
      }

      @include breakpoint(md) {
        display: block;
      }
    }

    .slick-arrow[aria-disabled="true"]{
      opacity: 0;
    }

    .slick-dots {
      li {
        margin: 0 8px;
        &.slick-active {
          button {
            &:before {
              color: $int-tool-hover-yellow;
            }
          }
        }
        button{
          &:before {
            font-size: 17px;
            color: $int-bright-blue-6;
            opacity: 1;
          }
        }
      }
    }
  }
  #density-b-calculator-form .selectize-input > .item,
  #cubic-feet-calculator-form .selectize-input > .item {
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
  }
  #density-b-calculator-form .density-select-weight .selectize-input > .item {
    max-width: 100%;
  }
  .selectize-control.single .selectize-input:after {
    right: 12px;
  }
  #density-select-length-selectized,
  #density-select-width-selectized,
  #density-select-height-selectized {
    color: transparent;
  }
  .col-sm-6.left {
    margin-right: 15px;
  }
  .col-sm-4.left {
    margin-right: 15px;
  }
  .col-sm-6 {
    width: 50%;
    padding: 0;
  }
  .col-sm-4 {
    width: 33.33333%;
    padding: 0;
  }
  .row.d-flex {
    display: flex;
    margin: 0;
    align-items: flex-end;
  }
  .row.pallet-group {
    margin: 0;
    & > div {
      margin-bottom: 5px;
    }
    & .pallet-group-radio {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      margin-bottom: 0;
      & .blue-text label {
        color: #0277f8;
      }
    }
  }
  & .pallet-group {
    .col-sm-4 {
      min-width: 99.33px;
    }
    .col-sm-8 {
      padding-top: 1.65em;
      width: 60%;
      padding: 0;
      div {
        label {
          padding-bottom: 0px;
          margin-bottom: -3px;
        }
      }
    }
  }
  & .pallet-group .col-sm-4 {
      width: 99.33px !important;
  }
}

.calculator-modal [type="radio"]:not(:checked) + label:before {
  border: 2px solid #8E8E8E;
}

.btn.stack-button.disabled {
  cursor: default;
  pointer-events: none;
}

.calculator-spacer {
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}