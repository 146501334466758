.case-studies {
    > .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    .page-wrapper {
        overflow-x: hidden;
    }

    .casestudies {
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
        height: 100vh;
        background-image: linear-gradient(to bottom, #0477F8 0%, $int-dark-blue-2 75vh);

        .row {
            max-width: 1200px;
            margin: auto auto;
        }

        //.case-studies-wrapper {
        //    position: absolute;
        //    top: 50%;
        //    left: 50%;
        //    transform: translate(-50%, -50%);
        //    z-index: 2;
        //}

        &--hero {
            text-align: center;
            margin-top: 13.75vh;
            position:relative;
            z-index: 2;

            @include breakpoint(sm){
                margin-top: 16.5vh;
            }
            @include breakpoint(md){
                //margin-top: 14.8vh;
                margin-top: 19vh;
            }
            @include breakpoint(lg){
                //margin-top: 15vh;
                margin-top: 25vh;
            }

            //@include breakpoint(lg){
            //    margin-top: 19vh;
            //}

            h1 {
                color: $int-yellow;
                font-size: 24px;
                line-height: 29px;
                margin: 0 ;

                @include breakpoint(sm){
                    font-size: 30px;
                    line-height: 36px;
                }

                @include breakpoint(md){
                    font-size: 36px;
                    line-height: 42px;
                }

                @include breakpoint(xlg){
                    font-size: 48px;
                    line-height: 48px;
                }
            }

            h2 {
                color: $int-light-blue;
                font-size: 24px;
                line-height: 29px;
                margin: 0;

                @include breakpoint(sm){
                    font-size: 30px;
                    line-height: 36px;
                }

                @include breakpoint(md){
                    font-size: 36px;
                    line-height: 42px;
                }

                @include breakpoint(xlg){
                    font-size: 48px;
                    line-height: 48px;
                }
            }

            p.hidden-at-sm {
                text-transform: uppercase;
                color: $int-bright-blue-2;
                font-size: 12px;
                line-height: 21px;
                margin-top: 19px;
                margin-bottom: 10px;
                font-family: $montserrat-bold;

                @include breakpoint(sm){
                    display: none;
                }
            }

            p.hidden-until-sm {
                color: $int-white;
                font-size: 16px;
                line-height: 24px;
                display: none;
                font-family: $montserrat-bold;

                @include breakpoint(sm){
                    display: block;
                    width: 377px;
                    margin: 15px auto 35px;
                }

                @include breakpoint(lg) {
                    width: 482px;
                    margin-bottom: 46px;
                    br {
                        display: none;
                    }
                }

                @include breakpoint(xlg) {
                    width: 558px;
                    font-size: 18px;
                    line-height: 30px;
                    margin-top: 25px;
                }
            }
        }

        .case-studies-swiper {
            position: relative;
            z-index: 3;
            width: 100%;
            max-width: 310px;
            margin: 0 auto;

            @include breakpoint(lg) {
                max-width: none;
                width: 808px;
            }

            .slick-list {
                overflow: visible;
            }

            .swiper-grid-item {
                background: $int-white;
                border: solid 1px $int-white;
                border-radius: 10px;
                text-align: center;
                position: relative;
                top: 0;
                transition: top 0.3s linear;

                &:hover {
                    img {
                        border: 9px solid $int-light-blue-4;
                        width: 80px;
                        height: 80px;
                        bottom: -41px;

                        @include breakpoint(lg){
                            width: 86px;
                            height: 86px;
                            bottom: -45px;
                        }
                    }
                }
                &:focus {
                    outline: none;
                }

                @include breakpoint(lg){
                    width: 192px;
                    display: inline-block;
                    margin: 0 5px;

                    &:hover {
                        top: -32px;
                        .content {
                            height: 141px;
                        }
                    }
                }

                .content {
                    height: 169px;

                    @include breakpoint(lg){
                        height: 109px;
                        transition: height 0.3s linear;
                    }
                }

                h3 {
                    color: $int-dark-blue-2;
                    height: 99px;
                    font-size: 18px;
                    line-height: 25px;
                    padding-top: 20px;
                    width: 240px;
                    margin: 43px auto 0;
                    text-transform: uppercase;

                    @include breakpoint(lg){
                        padding-top: 0;
                        width: 150px;
                        font-size: 14px;
                        line-height: 18px;
                        height: 74px;
                        margin-top: 30px;
                    }
                }
                p {
                    font-family: $montserrat-bold;
                    font-size: 12px;
                    line-height: 19px;
                    color: $int-torquoise;
                    text-transform: uppercase;
                    display: inline;

                    &:hover, &:focus {
                        outline: none;
                        text-decoration: none;
                    }
                }
                img {
                    width: 62px;
                    height: 62px;
                    position: absolute;
                    bottom: -32px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-radius: 50%;
                    transition: border 0.25s linear, width 0.25s linear, height 0.25s linear, bottom 0.25s linear;
                    border: 0 solid $int-light-blue-4;
                    background: $int-light-blue-4;

                    @include breakpoint(lg){
                        width: 68px;
                        height: 68px;
                        //border-width: 5px;
                        bottom: -36px;
                    }
                }
            }

            .slick-dots {
                top: 21px;
                bottom: auto;
                width: auto;
                left: 50%;
                transform: translateX(-50%);

                li {
                    width: 12px;
                    button {
                        width: 12px;
                        &:before {
                            width: 12px;
                            font-size: 12px;
                            opacity: 1;
                            color: $int-light-gray-2;
                            transition: color .2s linear;
                        }
                    }
                    &.slick-active, &:hover, &:focus {
                        button {
                            &:before {
                                color: $int-light-blue;
                            }
                        }
                    }
                }
            }
        }

        &--divider {
            height: 140px;
            background: #0431AF;
            overflow-y: visible;
            overflow-x: hidden;
            z-index: -1;

            &__img {
                position: absolute;
                z-index: 1;
                left: -507%;
                right: -500%;
                top: auto;
                bottom: 15px;
                margin: auto;
                height: 59%;

                @include breakpoint(lg){
                    width: 157.12vw;
                    height: auto;
                }

                @include breakpoint(lg){
                    width: 1912.12px;
                }
            }
        }
    }
}