$slick-font-path: "/fonts/";
$slick-loader-path: "/images/";

// Vendor
@import "./vendor/bootstrap";
@import "./vendor/mdb";
@import "./vendor/selectize";
@import "./vendor/slick/slick";
@import "./vendor/slick/slick-theme";
@import "./vendor/bootstrap-daterangepicker/daterangepicker.scss";


// Modules and Variables
@import "./partials/fonts";
@import "./partials/base";

// Partials
@import "./partials/nav";
@import "./partials/typography";
@import "./partials/buttons";
@import "./partials/daterange-picker";
@import "./partials/buttons";

@import "./partials/global";
@import "./partials/footer";
@import "./partials/quote-modal";
@import "./partials/calculator-modal";

//Pages
@import "./partials/carriers";
@import "./partials/casestudy";
@import "./partials/casestudies";
@import "./partials/about";
@import "./partials/services";
@import "./partials/tracking";
@import "./partials/home";
@import "./partials/online-tools";

// override non complient style in './vendor/mdb/'

html body.home {
	overflow: hidden !important;
}

.slick-track {
  margin: auto;
}
