.about-page {
  .page-wrapper {
    overflow-x: hidden;
  }
}
.about {
  padding-left: 30px;
  padding-right: 30px;

  .row {
    margin-left: -30px;
    margin-right: -30px;
  }

  .col-xs-12 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  &--hero {
    position: relative;
    height: 100vh;
    min-height: 100%;

    .section-header {
      max-width: 284px;
      width: 100%;
      margin: 0 auto;

      @include breakpoint(sm) {
        width: 383px;
        max-width: none;
      }
      @include breakpoint(md) {
        width: 574px;
      }
    }

    h1 {
      color: $int-yellow;
      font-size: 24px;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 12px;
      @include breakpoint(sm) {
        font-size: 36px;
        line-height: 43px;
      }
      @include breakpoint(md) {
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 7px;
      }
    }

    h2 {
      color: $int-bright-blue;
      font-size: 18px;
      line-height: 24px;
      max-width: 259px;
      width: 100%;
      margin: 0 auto;
      @include breakpoint(sm) {
        font-size: 30px;
        line-height: 43px;
        max-width: none;
        margin: 0 auto;
      }
      @include breakpoint(md) {
        font-size: 30px;
        line-height: 48px;
      }
    }

    p {
      color: $int-white;
      font-size: 14px;
      line-height: 21px;
      max-width: 444px;
      margin: 22px auto 0;
      @include breakpoint(sm) {
        font-size: 18px;
        line-height: 30px;
      }
      @include breakpoint(md) {
        margin-top: 60px;
        width: 57.8175vw;
        max-width: 522px;
      }
      @include breakpoint(lg) {
        margin-top: 27px;
        width: 52.621vw;
        max-width: 626px;
      }
      @include breakpoint(xlg) {
        font-size: 18px;
        line-height: 30px;
        margin-top: 35px;
      }
    }

    &__content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      @include breakpoint(sm) {
        top: 44.75%;
      }
      @include breakpoint(md) {
        top: 42%;
      }
    }
  }

  &--divider {
    width: 100%;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    margin: 0 auto;

    &__img {
      width: 208%;
      left: -54%;
      bottom: -1px;
      position: relative;
      display: block;

      @include breakpoint(sm) {
        width: 1096.160px;
        left: calc(50vw - (278px * 2));
      }
      @include breakpoint(lg) {
        width: 2242.50px;
        left: calc(50vw - (554px * 2));
      }
    }
  }

  &--thinkers {
    text-align: center;
    padding-bottom: 27px;
    background-color: $int-dark-blue-2;
    @include breakpoint(sm) {
      padding-bottom: 47px;
    }
    @include breakpoint(lg) {
      padding-top: 197px;
      padding-bottom: 50px;
    }
    @include breakpoint(xlg) {
      padding-top: 267px;
    }

    &__wrapper {
      max-width: 1200px;
      margin: 0 auto;
    }

    h2,
    h3,
    p {
      @include breakpoint(lg) {
        text-align: left;
      }
    }

    h2 {
      padding-top: 72px;
      font-size: 21px;
      color: $int-light-blue;
      margin-top: 0;
      @include breakpoint(sm) {
         font-size: 30px;
         padding-top: 98px;
         line-height: 36px;
       }
      @include breakpoint(md) {
      padding-top: 196px;
      }
      @include breakpoint(lg) {
        padding-top: 0;
        font-size: 36px;
        line-height: 40px;
      }
    }

    h3 {
      color: $int-yellow;
      text-transform: uppercase;
      font-size: 16px;
      text-align: center;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
      color: $int-white;
      padding-top: 17px;
      margin: 0 auto 7px;
      max-width: 400px;
      @include breakpoint(sm) {
        width: 95%;
        margin: 0 auto;
        font-size: 18px;
        line-height: 30px;
        padding-top: 8px;
      }
      @include breakpoint(lg) {
        width: 100%;
        margin-left: 0;
        padding-top: 24px;
        font-size: 16px;
        line-height: 24px;
        max-width: 360px;
      }
    }

    .heavy-lifters-image {
      top: 0;
      width: 76.875vw;
      padding-top: 20px;
      @include breakpoint(sm) {
        width: 420px;
        margin: 0 auto;
        padding-top: 26px;
      }
      @include breakpoint(lg) {
        width: calc(100% - 65px);
        padding-top: 0;
      }
    }

    &__content {
      @include breakpoint(lg) {

        &.col-xs-12 {
          padding-left: 0;
        }
      }
    }

    &__img-wrapper {
      min-height: 0;
      @include breakpoint(lg) {
        position: relative;
      }

      img {
        @include breakpoint(lg) {
          position: absolute;
          left: 60px;
        }
      }
    }

    &__span {
      display: flex;
      justify-content: center;
      margin-top: 13px;
      max-width: 320px;
      left: 50%;
      transform: translateX(-50%);

      @include breakpoint(sm) {
        margin-top: 35px;
      }
      @include breakpoint(lg) {
        justify-content: left;
        left: initial;
        max-width: none;
        transform: none;
        margin-top: 0;

        &.col-xs-12 {
          padding-left: 0;
        }
      }

      &left,
      &right {
        flex: 1;
        text-align: left;
        text-transform: uppercase;
        @include breakpoint(sm) {
          max-width: 150px;
        }

        span {
          margin-top: 15px;
          padding-left: 18px;
          display: block;
          font-size: 12px;
          line-height: 14px;
          color: $int-white;;
          font-family: $montserrat-bold;
          position: relative;

          @include breakpoint(lg) {
            margin-top: 30px;
          }

          &:nth-child(n+2) {
            margin-top: 23px;
          }

          &:before {
            content: '';
            display: block;
            height: 26px;
            border-left: 5px solid $int-yellow;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &space {
        flex-basis: 8px;
        @include breakpoint(lg) {
          flex-basis: 0;
        }
      }
    }

    &__meettheteam {
      padding-top: 28px;
      h3 {
        font-size: 14px;
        margin-bottom: 9px;
        @include breakpoint(sm) {
          font-size: 16px;
          margin-bottom: 8px;
        }
      }
      @include breakpoint(sm) {
        padding-top: 117px;
      }
      @include breakpoint(md) {
        padding-top: 70px;
      }
      @include breakpoint(lg) {
        padding-top: 180px;
      }
      @include breakpoint(xlg) {
        padding-top: 250px;
      }
    }

    &__arrow {
      width: 34px;
    }
  }

  &--team {
    text-align: center;
    margin-left: -30px;
    margin-right: -30px;
    background-image: linear-gradient(to bottom, $int-light-blue 37.18%, $int-bright-blue-3 100%);
    @include breakpoint(md) {
      padding: 100px 30px 80px;
    }
    .swiper-grid-container {
      position: relative;
      z-index: 1;

      @include breakpoint(md) {
        max-width: 690px;
      }
      @include breakpoint(lg) {
        max-width: 850px;
      }
    }
    .slick-slider {
      .slick-list {
        overflow: visible;
      }
    }
    .swiper-grid-wrapper {
      padding: 100px 0 74px;
      @include breakpoint(sm) {
        padding: 100px 0 0;
      }

      .swiper-grid-item {
        position: relative;
        @include breakpoint(sm) {
          height: 260px;

          .person {
            width: 240px;
            position: absolute;
          }

          &:nth-of-type(even) {
            .person {
              left: 0;
            }
          }

          &:nth-of-type(odd) {
            .person {
              right: 0;
            }
          }

          // Hacks to make the last person, if the total number of people is odd, left aligned instead of centered
          &:last-of-type:nth-child(odd) {
            width: auto !important;

            @include breakpoint(md) {
              width: 33.3333333333% !important;
            }
            @include breakpoint(lg) {
              width: 25% !important;
            }
          }
        }
        @include breakpoint(md) {
          .person {
            &:nth-of-type(even),
            &:nth-of-type(odd) {
              left: 50%;
              right: initial;
              transform: translateX(-50%);

              &:hover {
                transform: translate(-50%, -30px);
              }
            }
          }
        }
      }
    }

    .person {
      @include breakpoint(md) {
        height: 255px;
      }
      @include breakpoint(sm) {
        transition: transform .2s linear,
			width .2s linear,
			height .2s linear,
			opacity .2s linear;
        &:hover {
          transform: translateY(-30px);
          img {
            width: 170px;
            height: 170px;

            &~h3,
            &~h4,
            &~p {
              opacity: 1;
            }
          }
        }
      }
      img {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        @include breakpoint(sm) {
          width: 150px;
          height: 150px;
          transition: transform .2s linear,
			width .2s linear,
			height .2s linear,
			opacity .2s linear;





          &~h3,
          &~h4,
          &~p {
            opacity: 0;
            transition: opacity .2s linear;
          }
        }
      }
    }

    a,
    h3,
    h4 {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    h3 {
      color: $int-navy-blue;
      font-size: 18px;
      text-transform: uppercase;
      line-height: 24px;
      margin-top: 20px;

      @include breakpoint(sm) {
        margin-top: 5px;
      }
    }

    h4 {
      color: $int-white;
      font-size: 14px;
      text-transform: uppercase;
      margin: 0 auto;
      width: 203px;
    }

    a,
    p {
      color: $int-yellow;
      font-size: 12px;
      line-height: 14px;
      font-family: $montserrat-bold;
    }

    &__bg {
      position: relative;

      .box-pattern {
        background-image: url(/images/services-bg.svg);
        margin: 0;
        background-size: 234px 183px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
      }
    }

    &__controls {
      background: $int-xlight-gray;
      height: 80px;
      position: relative;
      bottom: 0;
      width: 100%;
      @include breakpoint(sm) {
        height: 136px;
      }
      @include breakpoint(md) {
        display: none;
      }

      .control-container {
        max-width: 303px;
        position: relative;
        margin: 0 auto;
      }

      p {
        text-transform: uppercase;
        color: $int-light-gray;
        font-size: 18px;
        line-height: 80px;
        font-family: $montserrat-regular;
        @include breakpoint(sm) {
          line-height: 136px;
        }
      }

      .carousel-control.right,
      .carousel-control.left {
        background-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%);
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
      }

      a.left.carousel-control {
        padding-left: 15px;
      }

      a.right.carousel-control {
        padding-right: 15px;
      }

      .carousel-control {
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__bg {
        background: $int-white;
        border-radius: 20px;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 16px;
          width: 100%;
        }
      }

      &__right {
        margin-right: -2px;
      }

      &__left {
        margin-left: -2px;
      }
    }
  }

  &--philosophy {
    background-color: $int-light-blue;
    padding: 22px 0 0;
    height: 568px;
    position: relative;
    z-index: 1;

    @include breakpoint(sm) {
      height: 788px;
    }
    @include breakpoint(md) {
      padding-top: 22px;
    }

    @include breakpoint(lg) {
      height: 800px;
    }

    &__wrapper {
      max-width: 1200px;
      margin: 0 auto;
    }

    .slick-dotted.slick-slider {
      margin-bottom: 0;
    }

    h2 {
      color: $int-dark-blue;
      text-align: center;
      font-size: 21px;
      line-height: 40px;
      margin-top: 30px;

      @include breakpoint(sm) {
        font-size: 30px;
        line-height: 68px;
        margin-top: 27px;
      }

      @include breakpoint(lg) {
        font-size: 36px;
        line-height: 40px;
        margin-top: 81px;
      }
    }

    .philosophy-block {
      @include breakpoint(lg) {
        display: block;
        width: 775px;
        margin: 0 auto;
      }

      .philosophy-swiper {
        .swiper-slide {
          height: 453px;
          position: relative;

          @include breakpoint(sm) {
            height: 648px;
          }

          @include breakpoint(lg) {
            height: 634px;
          }
          h3 {
            text-align: center;
            color: $int-yellow;
            font-size: 21px;
            line-height: 25px;
            text-transform: uppercase;
            margin: 22px 0 14px;

            @include breakpoint(sm) {
              font-size: 24px;
              line-height: 42px;
              margin: 35px 0 18px;
            }

            @include breakpoint(lg) {
              display: none;
            }
          }

          p {
            color: $int-white;
            text-align: center;
            font-size: 14px;
            line-height: 21px;
            width: 240px;
            margin: 0 auto;

            @include breakpoint(sm) {
              font-size: 18px;
              line-height: 30px;
              width: 408px;
            }

            @include breakpoint(lg) {
              font-size: 21px;
              line-height: 36px;
              width: 650px;
              margin: 160px auto 0;
              position: relative;
            }
          }

          img {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 125px;
          }
          &.philosophy-people img {
            height: 125px;

            @include breakpoint(sm) {
              height: 230px;
            }
            @include breakpoint(lg) {
              height: 260px;
            }
          }
          &.philosophy-integrity img{
            height: 125px;

            @include breakpoint(sm) {
              height: 205px;
            }
            @include breakpoint(lg) {
              height: 260px;
            }
          }
          &.philosophy-simplicity img {
            height: 120px;

            @include breakpoint(sm) {
              height: 225px;
            }
            @include breakpoint(lg) {
              height: 270px;
            }
          }
          &.philosophy-expertise img {
            height: 125px;

            @include breakpoint(sm) {
              height: 240px;
            }
            @include breakpoint(lg) {
              height: 300px;
            }
          }
        }
        .slick-dots {
          bottom: 155px;

          @include breakpoint(sm) {
            bottom: 264px;
          }

          @include breakpoint(lg) {
            top: 61px;
            bottom: auto;
          }

          li {
            margin: 0;
            width: 18px;

            @include breakpoint(sm) {
               width: 29px;
            }

            @include breakpoint(lg) {
              width: 136px;
              margin: 0 28.875px;
              justify-content: center;
              align-content: center;
              height: 50px;
              text-transform: uppercase;
              border-radius: 0;
              border: 0;
              text-indent: 0;
              font-size: 24px;
              line-height: 40px;
              color: $int-dark-blue;
              font-family: $montserrat-bold;
              border-bottom: solid 0px $int-yellow;
              transition: color 0.3s linear, border-bottom-width 0.2s linear;

              &:hover {
                color: $int-yellow;
              }
            }

            &.slick-active {
              button {
                &:before {
                  color: $int-white;
                  opacity: 1;
                }
              }
              @include breakpoint(lg) {
                color: $int-yellow;
                border-bottom-width: 2px;
              }
            }

            button {
              &:hover:before,
              &:focus:before,
              &:active:before {
                color: $int-white;


              }
              &:before {
                font-size: 12px;
                transition: color .2s linear,
                opacity .2s linear;

                @include breakpoint(sm) {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
