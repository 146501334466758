.home {
  .footer {
    z-index: 1;
  }
  .row {
    margin-left: -15px;
    margin-right: -15px;
    &.home--section {
      margin-left: 0;
      margin-right: 0;
    }
  }
  #background {
    position: fixed;
    height: 100%;
    width: 100%;

    &.released {
      position: absolute;
    }
  }
  .gradient-1 {
    background-color: $int-dark-blue-2;
    background-image: none;
    transition: background-color 0.3s linear;
  }
  &--section {
    position: relative;
    height: 100vh;
    min-height: 100%;

    .home--hero__content {
      position: absolute;
      transform: translateY(-50%);
      z-index: 1;
      top: 36.4vh;
    }
  }
  &--title-image {
    height: auto;
    position: relative;
    width: 78.125vw;
    max-width: 500px;
  }
  &--divider {
    width: 100%;
    position: absolute;
    bottom: 0;
    bottom: 0;
    overflow: hidden;
    margin: 0 auto;

    &__img {
      bottom: 0;
      position: relative;
      display: block;
      height: 100vh;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--layers {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    pointer-events: none;
  }
  &--truck__img {
    position: absolute;
    height: 100vh;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s linear;

    &.active {
      opacity: 1;
    }
  }

  &--everything__content {
    position: absolute;
    top: 38.5%;
    transform: translateY(-50%);
    z-index: 1;
    @include breakpoint(sm) {
      top: 41.7%;
    }
    @include breakpoint(md) {
      top: 46.2%;
    }
    @include breakpoint(lg) {
      top: 46.2%;
    }

    .section-header {
      max-width: 284px;
      width: 100%;
      margin: 0 auto;

      @include breakpoint(sm) {
        width: 415px;
        max-width: none;
      }
      @include breakpoint(md) {
        width: 574px;
      }
    }

    h1 {
      color: $int-nav-yellow;
      font-size: 24px;
      line-height: 29px;
      margin-top: 0;
      margin-bottom: 17px;
      @include breakpoint(sm) {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 25px;
      }
      @include breakpoint(md) {
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 26px;
      }
      @include breakpoint(lg) {
        margin-bottom: 26px;
      }
    }

    h2 {
      color: $int-bright-blue-4;
      font-size: 18px;
      line-height: 24px;
      max-width: 259px;
      width: 100%;
      margin: 0 auto;
      @include breakpoint(sm) {
        max-width: none;
      }

      @include breakpoint(md) {
        font-size: 21px;
        line-height: 30px;
        margin: 0 auto;
      }
    }
    p {
      color: $int-white;
      font-size: 15px;
      line-height: 21px;
      max-width: 259px;
      margin: 11px auto 0;
      @include breakpoint(sm) {
        max-width: 415px;
      }
      @include breakpoint(md) {
        font-size: 18px;
        line-height: 30px;
        margin-top: 11px;
        width: 100%;
        max-width: 550px;
      }
    }
  }
  &--make-it-easy__content {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    z-index: 1;
    @include breakpoint(sm) {
      top: 42%;
    }
    @include breakpoint(lg) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      top: 47.1%;
      width: 82%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 965px;
    }
    @include breakpoint(xlg) {
      width: 965px;
    }

    .section-header {
      max-width: 284px;
      width: 100%;
      margin: 0 auto;

      @include breakpoint(sm) {
        width: 383px;
        max-width: none;
      }
      @include breakpoint(md) {
        width: 574px;
      }
      @include breakpoint(lg) {
        text-align: left;
        display: inline-block;
        float: left;
        margin: 93px 0 0;
        width: 400px;
      }
      @include breakpoint(xlg) {
        width: 500px;
      }
    }

    h1 {
      color: $int-nav-yellow;
      font-size: 30px;
      line-height: 36px;
      margin-top: 0;
      margin-bottom: 25px;
      @include breakpoint(sm) {
        margin-bottom: 30px;
        font-size: 36px;
        line-height: 40px;
      }
      @include breakpoint(md) {
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 42px;
      }
      @include breakpoint(lg) {
        max-width: 100%;
        margin: 0 0 37px;
      }
    }

    h2 {
      color: $int-bright-blue-4;
      margin: 0 auto;
      display: none;
      @include breakpoint(md) {
        font-size: 21px;
        line-height: 30px;
        max-width: none;
        margin: 0;
      }
      @include breakpoint(lg) {
        max-width: 100%;
      }
    }
    p {
      color: $int-white;
      font-size: 15px;
      line-height: 21px;
      max-width: 259px;
      margin: 11px auto 0;
      display: none;
      @include breakpoint(md) {
        font-size: 18px;
        line-height: 30px;
        margin-top: 11px;
        width: 100%;
        max-width: 550px;
      }
      @include breakpoint(lg) {
        display: block;
        max-width: 100%;
        width: 100%;
        margin: 13px 0 0;
      }
    }

    .swiper-section {
      display: inline-block;
      width: 340px;
      position: relative;

      @include breakpoint(sm) {
        width: 400px;
      }

      @include breakpoint(md) {
        width: 500px;
      }

      @include breakpoint(lg) {
        //width: 465px;
        width: 399px;
      }
    }

    .make-it-easy-swiper {
      margin-left: -15px;
      margin-right: -15px;

      @include breakpoint(lg) {
        max-width: 600px;
        position: relative;
        margin-right: 0;
        margin-left: auto;
      }

      .make-it-easy-animation {
        width: 75% !important;

        @include breakpoint(md) {
          width: 70% !important;
        }

        @include breakpoint(lg) {
          width: 100% !important;
        }
      }

      .slick-dotted.slick-slider {
        margin-bottom: 60px;
      }

      .swiper-slide {
        width: 340px !important;
        height: auto !important;

        @include breakpoint(sm) {
          width: 400px !important;
        }

        @include breakpoint(md) {
          width: 500px !important;
        }

        @include breakpoint(lg) {
          //width: 465px !important;
          width: 399px !important;
        }
      }

      .slick-dots {
        bottom: unset;
        top: calc(48.6% + 125px);

        @include breakpoint(sm) {
          top: calc(48.6% + 145px);
        }

        @include breakpoint(md) {
          top: calc(48.6% + 175px);
        }

        @include breakpoint(lg) {
          top: calc(48.6% + 192px);
        }

        li {
          height: 10px;
          width: 10px;

          button {
            height: 10px;
            width: 10px;
            &:before {
              height: 10px;
              width: 10px;
              font-size: 11px;
              color: $int-bright-blue-4;
              opacity: 1;
            }
          }
          &.slick-active {
            button {
              &:before {
                color: $int-nav-yellow;
              }
            }
          }
        }
      }
    }
  }
  &--ways-to-help__content {
    position: absolute;
    top: 33%;
    transform: translateY(-50%);
    z-index: 1;

    @include breakpoint(lg) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      top: 54.1%;
      width: 82%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: left;
      min-height: 430px;
    }
    @include breakpoint(xlg) {
      min-height: 537px;
      width: 1110px;
    }

    .home--ways-to-help-ring__img {
      line-height: 0;
      position: relative;
      @include breakpoint(lg) {
        float: right;
      }

      > svg {
        width: 240px;

        @include breakpoint(md) {
          width: 390px
        }
        @include breakpoint(xlg) {
          width: 488px
        }
      }
    }

    .section {
      position: absolute;
      max-width: 284px;
      width: 100%;
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);

      @include breakpoint(sm) {
        width: 383px;
        max-width: none;
      }
      @include breakpoint(md) {
        width: 397px;
      }
      @include breakpoint(lg) {
        left: unset;
        transform: unset;
      }
      @include breakpoint(xlg) {
       width: 534px;
      }

      &.initial {
        @include breakpoint(md) {
          width: 510px;

          .section-content {
            margin-top: 58px;
          }

          .ways-to-help-title {
            height: 116px;
            margin-bottom: 23px;
          }

          h2 {
            font-size: 48px;
            line-height: 58px;
            max-width: 510px;
          }
          h3 {
            margin: 0 auto;
          }
        }

        @include breakpoint(lg) {
          width: 400px;

          .section-content {
            margin-top: 186px;
          }
          h2 {
            margin: 0 0 26px;
            font-size: 36px;
            line-height: 43px;
            max-width: 400px;
          }
          h3 {
            height: 0;
          }
        }

        @include breakpoint(xlg) {
          width: 510px;

          .section-content {
            margin-top: 254px;
          }
          h2 {
            font-size: 48px;
            line-height: 58px;
            max-width: 510px;
          }
        }
      }
    }

    .section-content {
      margin-top: 20px;

      @include breakpoint(md) {
        margin-top: 60px;
      }
      @include breakpoint(lg) {
        margin-top: 79px;
      }
      @include breakpoint(xlg) {
        margin-top: 142px;
      }
    }

    .ways-to-help-title {
      height: 50px;
      margin-bottom: 5px;
      position: relative;

      @include breakpoint(md) {
        height: 68px;
        margin-bottom: 23px;
      }

      @include breakpoint(lg) {
        height: 96px;
      }

      @include breakpoint(xlg) {
        height: 116px;
      }

    }

    h2 {
      color: $int-nav-yellow;
      font-size: 21px;
      line-height: 25px;
      max-width: 280px;
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);

      @include breakpoint(md) {
        font-size: 30px;
        line-height: 34px;
        max-width: 300px;
      }
      @include breakpoint(lg) {
        margin: 0 0;
        font-size: 36px;
        line-height: 48px;
        max-width: 400px;
      }
      @include breakpoint(xlg) {
        font-size: 48px;
        line-height: 58px;
        max-width: 534px;
      }
    }

    h3 {
      color: $int-bright-blue-4;
      font-size: 16px;
      line-height: 19px;
      max-width: 260px;
      width: 100%;
      margin: 0 auto 20px;
      height: 56px;
      vertical-align: top;
      @include breakpoint(md) {
        font-size: 21px;
        line-height: 24px;
        max-width: none;
        margin: 0 auto 58px;
        height: 48px;
      }
      @include breakpoint(lg) {
        margin: 0 0 40px;
        font-size: 24px;
        line-height: 29px;
        max-width: 400px;
        height: 87px;
      }
      @include breakpoint(xlg) {
        font-size: 30px;
        line-height: 36px;
        max-width: 534px;
        height: 72px;
      }
    }
    p {
      color: $int-white;
      font-size: 15px;
      line-height: 21px;
      max-width: 259px;
      margin: 11px auto 0;
      display: none;
      @include breakpoint(md) {
        font-size: 18px;
        line-height: 30px;
        margin-top: 11px;
        width: 100%;
        max-width: 550px;
      }
      @include breakpoint(lg) {
        display: block;
        width: 52.621vw;
        max-width: 626px;
        margin: 27px 0 0;
      }
    }
    .button-positioning {
      .btn.secondary-button {
        @include breakpoint(lg) {
         margin-left: 0;
        }
      }
    }
  }
  &--case-studies__content {
    position: relative;
    z-index: 1;
    top: 52%;
    transform: translateY(-50%);

    @include breakpoint(md) {
      top: 52%;
    }
    @include breakpoint(lg) {
      top: 54.5%
    }
    @include breakpoint(xlg) {
      margin-left: 8.75%;
      margin-right: 8.75%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 82.5%;
    }
    .case-studies-swiper {
      width: 260px;
      margin: 0 auto;
      @include breakpoint(md) {
        width: 400px;
      }
      @include breakpoint(lg) {
        width: 700px;
      }
      @include breakpoint(xlg) {
        width: 1050px;
      }
      @include breakpoint(xxlg) {
        width: 1400px;
      }

      .slick-slide {
        @include breakpoint(lg) {
          padding: 0 25px;
        }
      }

      .swiper-slide {
          background-color: $int-white;
          border-radius: 6px 6px 6px 6px;
          height: 421px;
          position: relative;

          @include breakpoint(md) {
            height: 634px;
          }
          @include breakpoint(lg) {
            height: auto;
          }

          .swiper-image {
            width: 100%;
          }
          img {
            height: 150px;
            width: auto;
            margin: 0 auto;
            border-radius: 6px 6px 0 0;
            background-color: white;
            @include breakpoint(md) {
              height: 232px;
            }
            @include breakpoint(lg) {
              height: 174px;
              width: 300px;
              margin: 0;
            }
          }
          .swiper-content {
            padding-left: 16px;
            padding-right: 16px;
            @include breakpoint(md) {
              padding-left: 23px;
              padding-right: 23px;
            }
            @include breakpoint(lg) {
              padding-left: 19px;
              padding-right: 19px;
		padding-bottom: 32px;
              min-height: 300px;
              width: 300px;
            }

            .title {
              margin: 18px 0 11px;
              max-height: 63px;
              @include breakpoint(md) {
                margin: 38px 0 25px;
                max-height: 108px;
              }
              @include breakpoint(lg) {
                margin-top: 30px;
                max-height: 81px;
              }

              h5, h6 {
                font-size: 15px;
                line-height: 21px;
                font-family: $montserrat-bold;
                margin: 0 auto;
                @include breakpoint(md) {
                  line-height: 36px;
                }
                @include breakpoint(lg) {
                  font-size: 17px;
                  line-height: 27px;
                }
              }
              h5 {
                color: $int-bright-blue-2;
                @include breakpoint(md) {
                  font-size: 23px;
                }
              }
              h6 {
                color: $int-light-blue;
                @include breakpoint(md) {
                  font-size: 21px;
                  line-height: 36px;
                }
              }
            }
            .intro {
              margin-bottom: 53px;
              @include breakpoint(md) {
                margin-bottom: 87px;
              }
              @include breakpoint(lg) {
                margin-bottom: 29px;
              }
              p {
                font-size: 14px;
                line-height: 21px;
                font-family: $montserrat-regular;
                color: $int-medium-gray;
                margin: 0 auto;

                @include breakpoint(md) {
                  font-size: 18px;
                  line-height: 30px;
                }
                @include breakpoint(lg) {
                  font-size: 14px;
                  line-height: 22px;
                }
            }

            }
            a {
              font-size: 11px;
              line-height: 17px;
              color: $int-light-blue;
              margin: 0 auto;
              text-transform: uppercase;
              font-family: $montserrat-bold;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: 19px;
              transition: color 0.3s linear;

              &:hover {
                color: $int-bright-blue-2;
              }

              @include breakpoint(md) {
                font-size: 16px;
                line-height: 26px;
                max-width: none;
              }
              @include breakpoint(lg) {
                font-size: 12px;
                line-height: 19px;
              }
            }
          }
        }
    }
    .slick-dots {
      bottom: unset;
      top: calc(55% + 188px);
      @include breakpoint(md) {
        top: calc(57% + 274px);
      }
      @include breakpoint(lg) {
        top: calc(62.2% + 220px);
      }
      li {
        height: 10px;
        width: 10px;
        button {
          height: 10px;
          width: 10px;
          &:before {
            height: 10px;
            width: 10px;
            font-size: 11px;
            color: $int-bright-blue-4;
            opacity: 1;
          }
        }
        &.slick-active {
          button {
            &:before {
              color: $int-dark-blue-2;
            }
          }
        }
      }
    }
  }
  &--values__content {
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    z-index: 1;

    @include breakpoint(lg) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      top: 54.1%;
      width: 82%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: left;
      min-height: 430px;
    }
    @include breakpoint(xlg) {
      min-height: 537px;
      width: 1110px;
    }

    .section-header {
      max-width: 284px;
      width: 100%;
      margin: 0 auto;

      @include breakpoint(sm) {
        width: 383px;
        max-width: none;
      }
      @include breakpoint(md) {
        width: 574px;
      }
      @include breakpoint(lg) {
        width: 398px;
        text-align: left;
        float: left;
        margin: 120px 0 0;

      }
      @include breakpoint(xlg) {
        width: 534px;
      }


      h1 {
        color: $int-nav-yellow;
        font-size: 24px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 25px;
        @include breakpoint(sm) {
          font-size: 36px;
        }
        @include breakpoint(md) {
          font-size: 48px;
          line-height: 58px;
          margin-bottom: 62px;
        }
        @include breakpoint(lg) {
          font-size: 36px;
          line-height: 43px;
          margin-bottom: 38px;
        }

        @include breakpoint(xlg) {
          font-size: 48px;
          line-height: 58px;

        }
      }
    }

    .home--values-ring__img {
      line-height: 0;
      position: relative;
      @include breakpoint(lg) {
        float: right;
      }

      > svg {
        width: 240px;

        @include breakpoint(md) {
          width: 390px;
        }
        @include breakpoint(xlg) {
          width: 488px;
        }
      }
    }

    .section-content {
      max-width: 284px;
      width: 100%;
      margin: 0 auto;
      position: relative;

      @include breakpoint(sm) {
        width: 383px;
        max-width: none;
      }
      @include breakpoint(md) {
         width: 574px;
      }
      @include breakpoint(lg) {
        width: 398px;
        float: left;
      }
      @include breakpoint(xlg) {
        width: 534px;
      }

      h2 {
        color: $int-bright-blue-4;
        font-size: 18px;
        line-height: 24px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        margin-top: 25px;
        margin-bottom: 0;
        @include breakpoint(sm) {
          font-size: 24px;
          line-height: 28px;

        }
        @include breakpoint(md) {
          line-height: 36px;
          margin-top: 50px;

        }
        @include breakpoint(lg) {
          font-size: 36px;
          line-height: 43px;
          margin-top: 0;
        }

        @include breakpoint(xlg) {
          font-size: 48px;
          line-height: 58px;
        }
      }
    }
  }

  #hero-background,
  #integrity-is-everything-background,
  #hero-cloud-background {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  #integrity-is-everything-background,
  #hero-cloud-background {
    width: 100%;
  }

  #hero-cloud-background {
    opacity: 0;
    transition: opacity 0.3s linear;

    &.active {
      opacity: 1;
    }
  }

  #ways-to-help-plenty,
  #ways-to-help-competitive-pricing,
  #ways-to-help-big-thinkers,
  #ways-to-help-road-to-rail,
  #ways-to-help-insurance-policy,
  #ways-to-help-misery-free-claims,
  #values-driving-us-everyday,
  #values-bridges-of-relationship,
  #values-pursue-the-best,
  #values-tell-the-truth,
  #values-honor-and-respect,
  #values-be-professional {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    svg {
      opacity: 0;
    }

    &.active {
      svg {
        opacity: 1;
      }
    }
  }

  .hero-animation,
  .integriy-is-everything-animation {
    height: 100vh !important;
    width: auto  !important;
  }

  .ways-to-help-animation,
  .values-animation {
    width: 240px !important;
    height: auto !important;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(md) {
      width: 390px !important;
    }
    @include breakpoint(xlg) {
      width: 488px !important;
    }
  }

  #one,
  #two,
  #three,
  #four,
  #five,
  #six,
  #seven {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s linear;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  #four .section {
    opacity: 0;
    transition: opacity 0.3s linear;

    &.active {
      opacity: 1;
      z-index: 1;
    }
  }
  #six .section {
    opacity: 0;
    transition: opacity 0.3s linear;

    &.active {
      opacity: 1;
    }
  }
}
div.slick-list {
	.slick-slider .slick-track {
		margin: auto;
	}
}
