html {
    width: 100%;
    height: 100%;
}
body {
    margin: 0;
    border: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background-color: #0431af;
    -webkit-overflow-scrolling: auto;
}
#fullpage {
    width: 100vw;
    left: -16px;
}
.page-wrapper {
    z-index: 2;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    //overflow-x: hidden;
    box-shadow: -12px 3px 12px rgba(0, 0, 0, 0.1), 12px 3px 12px rgba(0, 0, 0, 0.1);
    //bottom: 0;
}

.loading-overlay {
    z-index: 2147483646;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0431af;
}

.loading-spinner {
    //z-index: 2147483647;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.icon-vertical-divider {
    width: 3px;
    height: 30px;
}
